import React, { useEffect, useState } from "react";
import {  
    Collapse, 
    Form, 
    Input, 
    InputGroup, 
    InputGroupText,
    Modal, 
    ModalBody, 
    ModalFooter, 
    ModalHeader
} from "reactstrap";
import { ACTIVITIES_URL, corsConfig } from "../ServerConfig";
import { NumberInput } from "../util/NumberInput";
import axios from "axios";

export function NewDrillingModal(props) {
    const [activeRows, setActiveRows] = useState({});
    const [formData, setFormData] = useState({});

    useEffect(() => {
        if (props.activities === undefined || props.activityTree === undefined) return;
        const activityItems = props.activities.map(activity => activity.activityItem);
        for (let leaf of props.activityTree.find('Drilling').getLeaves()) {
            setActiveRows(values => ({...values, [leaf]:activityItems.includes(leaf) ? 'disabled' : false}))
        }
    }, [props.activities, props.activityTree]);

    const toggleActive = (activity) => {
        if (activeRows[activity] !== 'disabled') {
            if (activeRows[activity]) {
                let temp = formData;
                delete temp[activity];
                setFormData(temp);
            }
            setActiveRows(values => ({...values, [activity]:(!activeRows[activity])}));
        }
    }

    const handleChange = (event) => {
        const activityItem = Number(event.target.name.split('-')[0]);
        const field = event.target.name.split('-')[1];
        const value = event.target.value;
        if (!(activityItem in formData)) {
            let updateObj = {
                activityItem:activityItem,
                operatorNumber:props.operator,
                year:props.year,
                count:0
            }
            updateObj[field] = value;
            setFormData(values => ({...values, [activityItem]: updateObj}));
        } else {
            let updateObj = formData[activityItem];
            updateObj[field] = value;
            setFormData(values => ({...values, [activityItem]: updateObj}));
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        for (let activity in formData) {
            axios.post(ACTIVITIES_URL + '/create', formData[activity], corsConfig).then((response) => {
                setFormData({});
                props.toggle();
                props.reload();
            }).catch((error) => {
            console.error(error);
        });
        }
    }

    const mapActivitiesToRow = () => {
        if (props.activities === undefined || props.activityTree === undefined) return;
        const activities = Object.keys(activeRows).map(key => Number(key));
        return activities.map((activityItem, index) => {
            const node = props.activityTree.findByActivityItem(activityItem);
            return (
                <DrillingModalRow 
                    toggleActive={() => toggleActive(activityItem)} 
                    active={activeRows[activityItem]}
                    activityItem={activityItem}
                    name={node.activity}
                    handleChange={handleChange}
                    key={'drilling-modal-row-'+index}
                    data={formData[activityItem]}
                />
            )
        });
    }

    return (
        <Modal className="new-drilling-modal shadow-lg" isOpen={props.isOpen} toggle={props.toggle}>
            <ModalHeader toggle={props.toggle}><span>Add New Drilling Activity</span></ModalHeader>
            <Form onSubmit={handleSubmit}>
                <ModalBody>
                    {mapActivitiesToRow()}
                </ModalBody>
                <ModalFooter>
                    <Input type='submit' value='Submit' disabled={Object.keys(formData).length===0}/>
                </ModalFooter>
            </Form>
        </Modal>
    )
}

function DrillingModalRow(props) {

    const getCount = () => {
        if (props.data === undefined) return 0;
        return props.data.count;
    }

    const getHours = () => {
        if (props.data === undefined) return 0;
        return props.data.hours;
    }

    const getNotes = () => {
        if (props.data === undefined) return '';
        return props.data.notes;
    }

    const inputIsActive = (field) => {
        if (props.data === undefined) return false;
        return props.data[field] !== undefined;
    }

    return (
        <div className="modal-row">
            <div className="modal-row-head">
                <span 
                    className={"pill" + (props.active==='disabled' ? ' disabled' : '')}
                    onClick={props.toggleActive}
                    style={{backgroundColor:(props.active !== 'disabled' && props.active ? 'var(--primary)' : null)}}
                >
                    <span className="head-caret">{props.active==='disabled' ? '' : (props.active ?"\u2227":"\u2228")}</span>
                    <span className="head-text">{props.name}</span>
                </span>
            </div>
            <Collapse isOpen={props.active !== 'disabled' ? props.active : false}>
                <div className="collapse-content">
                    <div className="number-input-groups">
                        <InputGroup>
                            <InputGroupText 
                                style={inputIsActive('count')?{backgroundColor:'var(--primary)', color:'var(--text)'}:null}
                            >
                                # of Rigs
                            </InputGroupText>
                            <NumberInput name={props.activityItem + '-count'} value={getCount()} change={props.handleChange}/>
                        </InputGroup>
                        <InputGroup>
                            <InputGroupText 
                                style={inputIsActive('hours')?{backgroundColor:'var(--primary)', color:'var(--text)'}:null}
                            >
                                Total Hours
                            </InputGroupText>
                            <NumberInput name={props.activityItem + '-hours'} value={getHours()} change={props.handleChange}/>
                        </InputGroup>
                    </div>
                    <InputGroup className="notes-input-group">
                        <InputGroupText 
                                style={inputIsActive('notes')?{backgroundColor:'var(--primary)', color:'var(--text)'}:null}
                            >
                                Notes
                            </InputGroupText>
                        <Input type='textarea' name={props.activityItem + '-notes'} value={getNotes()} onChange={props.handleChange}/>
                    </InputGroup>
                </div>
            </Collapse>
        </div>
    )
}