import React from "react";
import { AddDrillingCard, DrillingCard } from "./DrillingCard";

export function DrillingPage(props) {

    const mapToDrillingCards = () => {
        if (props.activityTree === undefined || props.activities === undefined) return null;
        const root = props.activityTree.find('Drilling');
        
        const activityItems = props.activities.map(activity => activity.activityItem);
        return (root.children.map((child, index) => {
            if (activityItems.includes(child.activityItem)) {
                return <DrillingCard key={'drilling-card-'+index} node={child} activities={props.activities} delete={props.delete} />
            }
            return null;
        }))
    }

    const canAdd = () => {
        if (props.activityTree === undefined || props.activities === undefined) return null;
        const existingActivites = props.activities.map(activity => activity.activityItem);
        const possibleActivities = props.activityTree.find('Drilling').getLeaves();
        const hasAll = possibleActivities.every((activity) => existingActivites.includes(activity));
        return !hasAll && props.year !== undefined;
    }

    return (
        <div hidden={props.hidden} id="drilling-page" className="content-page overflow-auto">
            {mapToDrillingCards()}
            <AddDrillingCard onClick={() => props.setOpenModal('drilling')} hidden={!canAdd()} />
            
        </div>
    )
}

