const origin = window.location.origin;
const HOST = origin.match(/:/g).length > 1 ? origin.substring(0, origin.lastIndexOf(':')) : origin;
const PORT = HOST === 'https://wyblmemissions.net' ? '' : ':8080';
export const SERVER_URL = HOST + PORT
export const AUTH_URL = SERVER_URL + '/api/auth';
export const TEST_URL = SERVER_URL + '/api/test';
export const OPERATORS_URL = SERVER_URL + '/api/operators';
export const ACTIVITIES_URL = SERVER_URL + '/api/activity';
export const ANNUAL_REPORT_URL = SERVER_URL + '/api/annualreport';
export const EQUIPMENT_URL = SERVER_URL + '/api/equipment';
export const FACILITIES_URL = SERVER_URL + '/api/otherfacilities';
export const ERA_URL = SERVER_URL + '/api/era';
export const EMAIL_URL = SERVER_URL + '/api/email';

export const CONTACT_ADDRESS = 'blm-web@air-resource.com';

export const corsConfig = {
    origin: origin,
    withCredentials: true,
};