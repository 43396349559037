import React, { useState, useEffect } from "react";
import { Badge, Card, CardBody, CardTitle, Collapse, Input, ListGroup, ListGroupItem } from "reactstrap";
import { NumberInput } from "../util/NumberInput";
import axios from "axios";
import { default as trash } from '../resources/trash-svgrepo-com.svg';
import { ACTIVITIES_URL, corsConfig } from "../ServerConfig";

export function WellsCard(props) {
    const [notesOpen, setNotesOpen] = useState(false);
    const [notes, setNotes] = useState('');
    const [counts, setCounts] = useState({});

    useEffect(() => {
        if (props.node===undefined) return;

        setNotes(getNotes);
        for (let child of props.node.children) {
            setCounts(values => ({...values, [child.activityItem]:getCount(child.activityItem)}));
        }
    }, [props.activities])

    const findActivity = (activityItem) => {
        return props.activities.find(activity => activity.activityItem === activityItem);
    }

    const createPayload = (obj, node) => {
        let payload = {
            operatorNumber: props.operator,
            year: props.year,
            activityItem: node.activityItem
        };

        for (let key in obj) {
            payload[key] = obj[key];
        }

        return payload;
    }

    const getNotes = () => {
        const foundElement = findActivity(props.node.activityItem);
        if (foundElement !== undefined && foundElement.notes !== null) return foundElement.notes;
        return '';
    }

    const getCount = (activityItem) => {
        const foundElement = findActivity(activityItem);
        if (foundElement !== undefined) return foundElement.count;
        return 0;
    }

    const changeNotes = (event) => {
        setNotes(event.target.value);
        if (findActivity(props.node.activityItem) !== undefined) {
            axios.put(ACTIVITIES_URL + '/update', createPayload({notes:event.target.value}, props.node), corsConfig);
        } else {
            axios.post(ACTIVITIES_URL + '/create', createPayload({notes:event.target.value}, props.node), corsConfig);
        }
    }

    const changeCounts = (event, node) => {
        if (event.target.value === '') {
            setCounts(values => ({...values, [node.activityItem]:0}));
            return;
        }
        const value = parseInt(event.target.value);
        setCounts(values => ({...values, [node.activityItem]:value}));
        if (findActivity(node.activityItem) !== undefined) {
            axios.put(ACTIVITIES_URL + '/update', createPayload({activityItem:node.activityItem, count:value}, node), corsConfig);
        } else {
            axios.post(ACTIVITIES_URL + '/create', createPayload({activityItem:node.activityItem, count:value}, node), corsConfig);
        }
        
    }

    const deleteCard = () => {
        props.delete(props.node.getLeaves());
    }

    const deleteButton = () => {
        return (
            <div className="delete-button" onClick={deleteCard}><img src={trash}/></div>
        )
    }

    const toggleNotes = () => setNotesOpen(!notesOpen);

    const mapToListGroupItems = () => {
        if (props.node === undefined) return null;
        return props.node.children.map((child, index) => {
            return (
                <ListGroupItem key={props.passedKey + '-list-group-item-' + index}>
                    <div className="activity-number">
                        <NumberInput value={counts[child.activityItem]} change={(event) => changeCounts(event, child)}/>
                    </div>
                    <div className='activity-name'>{child.activity.toLowerCase()}</div>
                </ListGroupItem>
            )
        })
    }

    return  (
        <Card className="activity-card">
            <CardTitle className="card-title-wrapper">
                <div className="card-title-text">{props.node.activity}</div>
                {deleteButton()}    
            </CardTitle>
            <CardBody>
                <ListGroup horizontal>
                    {mapToListGroupItems()}
                </ListGroup>
                <div className="activity-notes" onClick={toggleNotes}>
                        <Badge pill>
                            Notes {notesOpen?"\u2227":"\u2228"}
                        </Badge>
                </div>
                <Collapse isOpen={notesOpen}>
                    <Input type="textarea" value={notes} onChange={changeNotes} rows={4}/>
                </Collapse>
            </CardBody>
        </Card>
    )
}

export function WellsCardGroup(props) {
    
    const mapToWellsCard = () => {
        if (props.node === undefined) return null;
        
        return (props.node.children.map((child, index) => {
            const leaves = child.getLeaves();
            const activityItems = props.activities.map(activity => activity.activityItem);
            for (let leaf of leaves) {
                if (activityItems.includes(leaf)) {
                    return <WellsCard 
                        key={'wells-card-'+index} 
                        passedKey={'wells-card-'+index} 
                        node={child} 
                        activities={props.activities}
                        operator={props.operator}
                        year={props.year}
                        delete={props.delete}
                    />
                }
            }
            return null;
        }))
    }

    return (
        <Card className="activity-card-group">
            <CardTitle>{props.node.activity}</CardTitle>
            <CardBody>
                {mapToWellsCard()}
            </CardBody>
        </Card>
    )
}

export function AddWellsCard(props) {
    return (
        <Card hidden={props.hidden} className="add-card" onClick={props.onClick} style={props.style}>
            <CardBody>
                <div className="add-card-body">
                    <div className="plus">+</div>
                    <div className="add-text">Add new...</div>
                </div>
            </CardBody>
        </Card>
    )
}