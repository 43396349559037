import React from "react";
import { default as logo } from './resources/blm_logo.svg';
import './styles/mobile.css';
import { default as trees } from './resources/christmas-ecology-forest-garden-leaf-plant-2-svgrepo-com.svg';

export function MobileView(props) {
    return (
        <div id='mobile-view'>
            <div id='mobile-header'>
                <a href="https://www.blm.gov/">
                    <div className='logo'>				
                        <img src={logo} id='logo'/>
                        <div className='logo-text'>
                            <div id='logo-text-top'>
                                U.S. DEPARTMENT OF THE INTERIOR
                            </div>
                            <div id='logo-text-main'>
                                BUREAU OF LAND MANAGEMENT
                            </div>
                        </div>
                    </div>
                </a>
            </div>
            <div id='mobile-body'>
                <div id='mobile-trees-wrapper'>
                    <img src={trees} id='mobile-trees' />
                </div>
                <p>Site is unavailable on mobile.</p>
                <p>Please visit the site in a desktop browser to view wyblmemissions.net.</p>
            </div>
        </div>
    )
}