export const fields = [
"WC",
"ECHO SPRINGS",
"MONUMENT LAKE",
"GREAT DIVIDE",
"LUMAN RIM",
"LABARGE",
"SIBERIA RIDGE",
"WILD ROSE",
"TWO RIM",
"DESERT FLATS",
"WAMSUTTER",
"STRIKE",
"LEWIS",
"BATTLE SPRINGS",
"EMIGRANT TRAIL",
"TIERNEY",
"STANDARD DRAW",
"RED DESERT",
"BARREL SPRINGS",
"FREWEN",
"RED LAKE",
"LOST CREEK BASIN",
"IRON PIPE",
"FIVE MILE GULCH",
"BITTER CREEK",
"COAL GULCH",
"FIVE MILE",
"DESERT SPRINGS",
"CRESTON",
"WINDY HILL",
"BLUE GAP",
"STANDARD SRAW",
"STOCK POND",
"WELLS BLUFF",
"LANEY WASH",
"TABLE ROCK SOUTHWEST",
"HANSEN DRAW",
"HIGGINS",
"SENTINEL RIDGE",
"TABLE ROCK",
"SHEEP CAMP",
"PRIMITIVE",
"SHELL CREEK",
"SALAZAR",
"SIBERIA STATE",
"TEN MILE DRAW",
"SMITH RANCH",
"DELANEY RIM",
"GALE",
"CONTINENTAL DIVIDE",
"STAGE STOP",
"ALKALINE CREEK",
"WILLOW RESERVOIR"
]