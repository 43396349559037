export const buildTree = (data) => {
    let tree = {
        find: function(activity) {
            for (let x in this) {
                if (this[x].activity === activity) return this[x];
            }
            return null;
        },
        findByActivityItem: function(activityItem) {
            for (let x in this) {
                if (this[x].activityItem === activityItem) return this[x];
            }
            return null;
        }
    };
    for (let activity of data) {
        let node = new TreeNode(activity.activityItem, activity.activity, activity.parent);
        tree[node.activityItem] = node;
        if (node.parent!==null) {
            node.parentNode = tree[node.parent];
            tree[node.parent].addChild(node);
        }
    }
    return tree;
}

class TreeNode {
    children = []
    constructor(activityItem, activity, parent=null, parentNode=null) {
        this.activityItem = activityItem;
        this.activity = activity;
        this.parent = parent;
        this.parentNode = parentNode;
    }

    isLeaf() {
        return this.children.length===0;
    }

    addChild(node) {
        this.children.push(node)
    }

    getActivity() {
        return this.activity;
    }

    getLeaves() {
        let leaves = [];
        if (this.children.length === 0) {
            leaves.push(this.activityItem);
            return leaves;
        }
        for (let child of this.children) {
            if (child.children.length===0) {
                leaves.push(child.activityItem);
            } else {
                leaves = leaves.concat(child.getLeaves());
            }
        }
        return leaves;
    }

    getLeafNodes() {
        let leaves = [];
        if (this.children.length === 0) {
            leaves.push(this);
            return leaves;
        }
        for (let child of this.children) {
            if (child.children.length===0) {
                leaves.push(child);
            } else {
                leaves = leaves.concat(child.getLeafNodes());
            }
        }
        return leaves;
    }
}