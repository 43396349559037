import React, { useEffect, useState } from 'react';
import { Form, Input, InputGroup, InputGroupText, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { ANNUAL_REPORT_URL, AUTH_URL, OPERATORS_URL, corsConfig } from '../ServerConfig';
import axios from 'axios';
import { getReportingYear } from '../util/ReportingYear';

export function NewReportModal(props) {
    const [formData, setFormData] = useState({});
    const [validFields, setValidFields] = useState({});

    const thisYear = getReportingYear();

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setFormData(values => ({...values, [name]: value}))
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        for (let field of Object.keys(validFields)) {
            if (!validFields[field]) return;
        }
        const request = {
            "operatorNumber":props.operatorNumber,
            "year":thisYear,
            "contactName":`${formData.firstName} ${formData.lastName}`,
            "contactEmail":formData.email,
            "notes":formData.notes
        };
        axios.post(ANNUAL_REPORT_URL + '/create', request, corsConfig).then((response) => {
            props.reload();
            props.toggle();
        }).catch((error) => {
            console.error(error);
        });
    }

    const validateEmail = () => {
        const matches = formData.email.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)!==null;
        return matches;
    }

    useEffect(() => {
        if (formData.firstName!==undefined) {
            setValidFields(values => ({...values, firstName: formData.firstName!==''}));
        }

        if (formData.lastName!==undefined) {
            setValidFields(values => ({...values, lastName: formData.lastName!==''}));
        }

        if (formData.email!==undefined) {
            setValidFields(values => ({...values, email: validateEmail()}));
        }
    }, [formData])
        

    return (
        <Modal toggle={props.toggle} className='new-report-modal shadow-lg' isOpen={props.isOpen}>
            <ModalHeader toggle={props.toggle}>Create New Report for {thisYear}</ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit}>
                    <p><b>Contact Info</b></p>
                    <InputGroup>
                        <InputGroupText>First Name</InputGroupText>
                        <Input valid={formData.firstName!==undefined && validFields.firstName} 
                            invalid={formData.firstName!==undefined && !validFields.firstName} 
                            name='firstName' 
                            placeholder='First Name' 
                            onChange={handleChange}
                        />
                    </InputGroup>
                    <br/>
                    <InputGroup>
                        <InputGroupText>Last Name</InputGroupText>
                        <Input valid={formData.lastName!==undefined && validFields.lastName} 
                            invalid={formData.lastName!==undefined && !validFields.lastName} 
                            name='lastName' 
                            placeholder='Last Name' 
                            onChange={handleChange}
                        />
                    </InputGroup>
                    <br/>
                    <InputGroup>
                        <InputGroupText>Email</InputGroupText>
                        <Input valid={formData.email!==undefined && validFields.email} 
                            invalid={formData.email!==undefined && !validFields.email}  
                            type='email' 
                            name='email'
                            placeholder='email@domain.com' 
                            onChange={handleChange}
                        />
                    </InputGroup>
                    <br/>
                    <InputGroup className="notes-input-group">
                        <InputGroupText>Notes</InputGroupText>
                        <Input type='textarea' name={'notes'} onChange={handleChange} placeholder='Optional...'/>
                    </InputGroup>
                    <br/>
                    <Input type='submit' value='Submit'/>
                </Form>
            </ModalBody>
        </Modal>
    );
}