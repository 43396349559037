import React, { useEffect, useState } from "react";
import './styles/content.css';
import './styles/sideNav.css';
import { ACTIVITIES_URL, ANNUAL_REPORT_URL, EQUIPMENT_URL, ERA_URL, FACILITIES_URL, corsConfig } from "./ServerConfig";
import axios from "axios";
import { DrillingPage } from "./content/DrillingPage";
import { WellsPage } from "./content/WellsPage";
import { WellPadsPage } from "./content/WellPadsPage";
import { OtherFacilitiesPage } from "./content/OtherFacilitiesPage";
import { ERAPage } from "./content/ERAPage";
import { NewReportModal } from "./modals/NewReportModal";
import { NewDrillingModal } from "./modals/NewDrillingModal";
import { NewWellsModal } from "./modals/NewWellsModal";
import { NewWellPadsModal } from "./modals/NewWellPadsModal";
import { NewFacilityModal } from "./modals/NewFacilityModal";
import { NewERAModal } from "./modals/NewERAModal";
import { SideNav } from "./SideNav";
import { Dashboard } from "./content/Dashboard";
import { default as trees } from "./resources/christmas-ecology-forest-garden-leaf-plant-2-svgrepo-com.svg";
import { getReportingYear } from "./util/ReportingYear";

// Config
const defaultTab = 'dashboard';
const currentYear = getReportingYear();

export function Content(props) {
    const [activeTab, setActiveTab] = useState(defaultTab);
    const [activities, setActivities] = useState();
    const [year, setYear] = useState(currentYear);
    const [reportYears, setReportYears] = useState([]);
    const [operator, setOperator] = useState('');
    const [operatorName, setOperatorName] = useState('');
    const [equipment, setEquipment] = useState([]);
    const [otherFacilities, setOtherFacilities] = useState([]);
    const [era, setEra] = useState([]);
    const [annualReport, setAnnualReport] = useState({});
    const [openModal, setOpenModal] = useState(null);

    const payload = {
        operatorNumber:operator, 
        year:year
    };

    useEffect(() => {
        if (props.account!==undefined && props.account.operatorNumber !== null && props.account.operatorNumber !== 'BLM') {
            setOperator(props.account.operatorNumber);
            setOperatorName(props.account.operatorName);
        }
        else if (props.account === undefined) {
            setActiveTab(defaultTab);
            setActivities();
            setYear(currentYear);
            setReportYears([]);
            setOperator('');
            setEquipment([]);
            setOtherFacilities([]);
            setEra([]);
            setOpenModal(null);
        }
    }, [props.account])

    useEffect(() => {
        setActiveTab('dashboard');

        if (props.account!==undefined) {
            loadActivities();
            
            loadReportYears();

            getTabData();

            loadAnnualReport();
        }
    }, [operator]);
    
    useEffect(() => {
        setYear(reportYears[reportYears.length-1])
    }, [reportYears]);

    useEffect(() => {
        if (props.account!==undefined) {
            loadActivities();

            getTabData();

            loadAnnualReport();
        }
    }, [year]);

    useEffect(() => {
        if (props.account!==undefined) {
            loadActivities();

            getTabData();
        }
    }, [activeTab]);

    useEffect(() => {
        setActiveTab('dashboard');
    }, [annualReport])

    const changeYear = (event) => {
        setYear(event.target.value);
    }
    
    const getTabData = () => {
        loadEquipment();
        loadFacilities();
        loadEra();
    }

    const loadReportYears = () => {
        axios.post(ANNUAL_REPORT_URL + '/years', {input:operator}, corsConfig).then((response) => {
            setReportYears(response.data);
        }).catch((error) => {
            console.error(error);
        });
    }

    const loadActivities = () => {
        axios.post(ACTIVITIES_URL + '/all', payload, corsConfig).then((response) => {
            setActivities(response.data.activities);
        }).catch((error) => {
            console.error(error);
        });
    }

    const loadEquipment = () => {
        axios.post(EQUIPMENT_URL, payload, corsConfig).then((response) => {
            let data = response.data;
            data.sort((a,b) => {
                if (a.type > b.type) {
                    return 1;
                } else if (a.type < b.type) {
                    return -1;
                } else {
                    if (a.type === b.type) {
                        if (a.subtype > b.subtype) {
                            return 1;
                        } else if (a.subtype < b.subtype) {
                            return -1;
                        } else {
                            return 0;
                        }
                    }
                }
            });
            setEquipment(data);
        }).catch((error) => {
            console.error(error);
        });
    }

    const loadFacilities = () => {
        axios.post(FACILITIES_URL, payload, corsConfig).then((response) => {
            setOtherFacilities(response.data);
        }).catch((error) => {
            console.error(error);
        });
    }

    const loadEra = () => {
        axios.post(ERA_URL, payload, corsConfig).then((response) => {
            setEra(response.data);
        }).catch((error) => {
            console.error(error);
        });
    }

    const loadAnnualReport = async () => {
        if (operator === undefined || year === undefined) return;
        await axios.post(ANNUAL_REPORT_URL, payload, corsConfig).then((response) => {
            setAnnualReport(response.data);
        }).catch((error) => {
            console.error(error);
        });
    }

    const toggleModal = (modalName) => {
        if (openModal !== modalName) {
            setOpenModal(modalName)
        } else {
            setOpenModal(null);
        }
    }

    const createNewReport = () => {
        const thisYear = getReportingYear();
        const request = {
            "operatorNumber":props.account.operatorNumber,
            "year":thisYear,
            "contactName":`${props.account.firstName} ${props.account.lastName}`,
            "contactEmail":props.account.email
        };
        axios.post(ANNUAL_REPORT_URL + '/create', request, corsConfig).then((response) => {
            loadReportYears();
        }).catch((error) => {
            console.error(error);
        });
    }

    const deleteActivity = (activityItems) => {
        const payload = {
            "operatorNumber":operator,
            "year":year,
            "activityItems": activityItems
        }
        axios.post(ACTIVITIES_URL + '/delete', payload, corsConfig).then(() => {
            loadActivities();
        }).catch((error) => {
            console.error(error);
        })
    }

    const deleteEquipment = (equipmentId) => {
        const payload = {
            "id":equipmentId,
            "operatorNumber":operator
        }
        axios.post(EQUIPMENT_URL + '/delete', payload, corsConfig).then(() => {
            loadEquipment();
        }).catch((error) => {
            console.error(error);
        })
    }

    const deleteFacility = (facilityId) => {
        const payload = {
            "id":facilityId,
            "operatorNumber":operator
        }
        axios.post(FACILITIES_URL + '/delete', payload, corsConfig).then(() => {
            loadFacilities();
        }).catch((error) => {
            console.error(error);
        })
    }

    const deleteEra = (eraId) => {
        const payload = {
            "id":eraId,
            "operatorNumber":operator
        }
        axios.post(ERA_URL + '/delete', payload, corsConfig).then(() => {
            loadEra();
        }).catch((error) => {
            console.error(error);
        })
    }

    const renderActiveTab = () => {
        switch(activeTab) {
            case 'drilling':
                return (
                    <DrillingPage 
                        hidden={operator==='' || reportYears.length===0 || activeTab!=='drilling'} 
                        activities={activities} 
                        activityTree={props.activityTree}
                        setOpenModal={setOpenModal}
                        year={year}
                        delete={deleteActivity}
                    />
                );
            case 'wells':
                return (
                    <WellsPage 
                        hidden={operator==='' || reportYears.length===0 || activeTab!=='wells'} 
                        activities={activities} 
                        activityTree={props.activityTree}
                        setOpenModal={setOpenModal}
                        operator={operator}
                        year={year}
                        delete={deleteActivity}
                    />
                );
            case 'well-pads':
                return (
                    <WellPadsPage 
                        hidden={operator==='' || reportYears.length===0 || activeTab!=='well-pads'} 
                        activities={activities} 
                        activityTree={props.activityTree}
                        equipment={equipment}
                        operator={operator}
                        setOpenModal={setOpenModal}
                        year={year}
                        delete={deleteActivity}
                        deleteEquipment={deleteEquipment}
                    />
                );   
            case 'other-facilities':
                return (
                    <OtherFacilitiesPage
                        hidden={operator==='' || reportYears.length===0 || activeTab!=='other-facilities'}
                        otherFacilities={otherFacilities}
                        setOpenModal={setOpenModal}
                        year={year}
                        operator={operator}
                        delete={deleteFacility}
                    />
                );
            case 'era':
                return (
                    <ERAPage
                        hidden={operator==='' || reportYears.length===0 || activeTab!=='era'}
                        era={era}
                        setOpenModal={setOpenModal}
                        year={year}
                        delete={deleteEra}
                    />
                );
            default:
                return null;
        }
    }

    const noContent = () => {
        if (operator === '') {
            return (
                <div id='no-content'>
                    <img src={trees} id='trees' />
                    Select an operator to get started.
                </div>
            )
        } else if (reportYears.length === 0) {
            return (
                <div id='no-content'>
                    <img src={trees} id='trees' />
                    No reports found.<br/>
                    Create a report for this year to get started.
                </div>
            )
        }
    }

    return (
        <div hidden={!props.visible} className="content-area">
            <SideNav
                account={props.account}
                operator={operator}
                setOperator={setOperator}
                operatorName={operatorName}
                setOperatorName={setOperatorName}
                reportYears={reportYears}
                reportSubmitted={annualReport.submitted}
                year={year}
                changeYear={changeYear}
                setOpenModal={setOpenModal}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                createNewReport={createNewReport}
            />


            {/* Content Pages */}
            <div className="main-content">
                <Dashboard
                    hidden={operator==='' || reportYears.length===0 || activeTab!=='dashboard'} 
                    operatorName={operatorName}
                    account={props.account}
                    activities={activities} 
                    activityTree={props.activityTree}
                    equipment={equipment}
                    facilities={otherFacilities}
                    era={era}
                    report={annualReport}
                    reloadReport={loadAnnualReport}
                />
                {noContent()}
                {renderActiveTab()}
            </div>

            
            {/* Modals */}
            <NewDrillingModal 
                toggle={() => toggleModal('drilling')} 
                isOpen={openModal==='drilling'} 
                activities={activities}
                activityTree={props.activityTree}
                operator={operator}
                year={year}
                reload={loadActivities}
            />
            <NewWellsModal 
                toggle={() => toggleModal('wells')} 
                isOpen={openModal==='wells'} 
                activities={activities}
                activityTree={props.activityTree}
                operator={operator}
                year={year}
                reload={loadActivities}
            />
            <NewWellPadsModal
                toggle={() => toggleModal('well-pads')} 
                isOpen={openModal==='well-pads'} 
                activities={activities}
                activityTree={props.activityTree}
                equipment={equipment}
                equipmentTypes={props.equipmentTypes}
                operator={operator}
                year={year}
                reload={() => {loadActivities(); loadEquipment();}}
            />
            <NewFacilityModal
                toggle={() => toggleModal('other-facilities')} 
                isOpen={openModal==='other-facilities'} 
                facilities={otherFacilities}
                operator={operator}
                year={year}
                reload={loadFacilities}
            />
            <NewERAModal
                toggle={() => toggleModal('era')} 
                isOpen={openModal==='era'} 
                era={era}
                operator={operator}
                year={year}
                reload={loadEra}
            />
            <NewReportModal 
                toggle={() => toggleModal('new-report')}
                isOpen={openModal==='new-report'}
                operatorNumber={operator}
                year={year}
                reload={loadReportYears}
            />
        </div>
    )
}

