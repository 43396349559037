import React, { useState, useEffect } from "react";
import { Badge, Card, CardBody, CardTitle, Collapse, Input, ListGroup, ListGroupItem } from "reactstrap";
import { NumberInput } from "../util/NumberInput";
import axios from "axios";
import { ACTIVITIES_URL, corsConfig } from "../ServerConfig";
import { NumberDecimalInput } from "../util/NumberDecimalInput";
import { default as trash } from '../resources/trash-svgrepo-com.svg';

export function WellPadsCard(props) {
    const [notesOpen, setNotesOpen] = useState(false);
    const [notes, setNotes] = useState('');
    const [count, setCount] = useState({});
    const [acres, setAcres] = useState({});
    
    useEffect(() => {
        setNotes(getNotes());
        setCount(getCount());
        setAcres(getAcres());
    }, [props.activities])

    const createPayload = (obj) => {
        const foundElement = findActivity(props.node.activityItem);
        let payload = {
            operatorNumber: foundElement.operatorNumber,
            year: foundElement.year,
            activityItem: foundElement.activityItem
        };

        for (let key in obj) {
            payload[key] = obj[key];
        }

        return payload;
    }

    const findActivity = (activityItem) => {
        return props.activities.find(activity => activity.activityItem === activityItem);
    }

    const getNotes = () => {
        const foundElement = findActivity(props.node.activityItem);
        if (foundElement !== undefined && foundElement.notes !== null) return foundElement.notes;
        return '';
    }

    const getCount = () => {
        const foundElement = findActivity(props.node.activityItem);
        if (foundElement !== undefined) return foundElement.count;
        return 0;
    }

    const getAcres = () => {
        const foundElement = findActivity(props.node.activityItem);
        if (foundElement !== undefined) return 0 + foundElement.acres;
        return 0;
    }

    const getName = () => {
        return props.node === undefined ? null : props.node.activity;
    }

    const changeCount = (event) => {
        if (event.target.value === '') {
            setCount(0);
            return;
        }
        const value = parseInt(event.target.value);
        setCount(value);
        axios.put(ACTIVITIES_URL + '/update', createPayload({count:value}), corsConfig);
    }

    const changeAcres = (event) => {
        if (event.target.value === '') {
            setAcres(0.0);
            return;
        }
        const value = parseFloat(event.target.value);
        console.log(value);
        setAcres(value);
        axios.put(ACTIVITIES_URL + '/update', createPayload({acres:value}), corsConfig);
    }

    const changeNotes = (event) => {
        setNotes(event.target.value);
        axios.put(ACTIVITIES_URL + '/update', createPayload({notes:event.target.value}), corsConfig);
    }

    const deleteCard = () => {
        props.delete([props.node.activityItem]);
    }

    const deleteButton = () => {
        return (
            <div className="delete-button" onClick={deleteCard}><img src={trash}/></div>
        )
    }

    const toggleNotes = () => setNotesOpen(!notesOpen);

    return  (
        <Card className="activity-card">
            <CardTitle className="card-title-wrapper">
                <div className="card-title-text">{getName()}</div>
                {deleteButton()}    
            </CardTitle>
            <CardBody>
                <ListGroup horizontal>
                    <ListGroupItem>
                        <div className="activity-number primary-metric">
                            <NumberInput value={count} change={changeCount}/>
                        </div>
                        <div className='activity-name'>new pads</div>
                    </ListGroupItem>
                    
                    <ListGroupItem>
                        <div className="activity-number">
                            <NumberDecimalInput value={acres} change={changeAcres}/>
                        </div>
                        <div className='activity-name'>total acres</div>
                    </ListGroupItem>
                </ListGroup>
                <div className="activity-notes" onClick={toggleNotes}>
                        <Badge pill>
                            Notes {notesOpen?"\u2227":"\u2228"}
                        </Badge>
                </div>
                <Collapse isOpen={notesOpen}>
                    <Input type="textarea" value={notes} onChange={changeNotes}/>
                </Collapse>
            </CardBody>
        </Card>
    )
}

export function WellPadsCardGroup(props) {
    
    const mapToWellPadsCard = () => {
        if (props.node === undefined) return null;
        return (props.node.children.map((child, index) => {
            const leaves = child.getLeaves();
            const activityItems = props.activities.map(activity => activity.activityItem);
            for (let leaf of leaves) {
                if (activityItems.includes(leaf)) {
                    return <WellPadsCard 
                        key={'well-pads-card-'+index} 
                        passedKey={'well-pads-card-'+index} 
                        node={child} 
                        activities={props.activities}
                        delete={props.delete}
                    />
                }
            }
            return null;
        }))
    }

    return (
        <Card className="activity-card-group">
            <CardTitle>{props.node.activity}</CardTitle>
            <CardBody>
                {mapToWellPadsCard()}
            </CardBody>
        </Card>
    )
}

export function AddWellPadsCard(props) {
    return (
        <Card hidden={props.hidden} className="add-card" onClick={props.onClick} style={props.style}>
            <CardBody>
                <div className="add-card-body">
                    <div className="plus">+</div>
                    <div className="add-text">Add new...</div>
                </div>
            </CardBody>
        </Card>
    )
}