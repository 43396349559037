import React from "react";
import { AddWellPadsCard, WellPadsCardGroup } from "./WellPadsCard";
import { EquipmentCardGroup } from "./EquipmentCard";

export function WellPadsPage(props) {

    const mapToWellPadsCardGroup = () => {
        if (props.activityTree === undefined || props.activities === undefined) return null;
        const root = props.activityTree.find('Well Pads');
        
        return (root.children.map((child, index) => {
            const leaves = child.getLeaves();
            const activityItems = props.activities.map(activity => activity.activityItem);
            for (let leaf of leaves) {
                if (activityItems.includes(leaf)) {
                    return <WellPadsCardGroup 
                        key={'well-pads-card-group-'+index} 
                        node={child} 
                        activities={props.activities}
                        delete={props.delete}
                    />
                }
            }
            return null;
        }))
    }

    const equipmentCardGroup = () => {
        if (props.equipment === undefined || props.equipment.length === 0) return null;
        return <EquipmentCardGroup 
            equipment={props.equipment} 
            operator={props.operator}
            delete={props.deleteEquipment}
        />
    }

    const canAdd = () => {
        return props.year !== undefined;
    }

    const hasNoWellPadsActivity = () => {
        if (props.activityTree === undefined || props.activities === undefined) return null;
        const existingActivites = props.activities.map(activity => activity.activityItem);
        const possibleActivities = props.activityTree.find('Well Pads').getLeaves();
        return !possibleActivities.some(activity => existingActivites.includes(activity));
    }

    return (
        <div hidden={props.hidden} id="well-pads-page" className="content-page overflow-auto">
            {mapToWellPadsCardGroup()}
            {equipmentCardGroup()}
            <AddWellPadsCard onClick={() => props.setOpenModal('well-pads')} hidden={!canAdd()} style={hasNoWellPadsActivity() ? {marginTop:'11px'} : null} />
        </div>
    )
}