import React from "react";

export function NumberDecimalInput(props) {

    const handleKeypress = (e) => {
        const excepted = ['Backspace', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab', '.']
        const characterCode = e.key;
        if (excepted.includes(characterCode)) {
            return;
        }

        const characterNumber = Number(characterCode);
        if (characterNumber >= 0 && characterNumber <= 9) {
          if (e.currentTarget.value && e.currentTarget.value.length) {
            return;
          } else if (characterNumber === 0) {
            e.preventDefault();
          }
        } else {
          e.preventDefault();
        }
    }

    const getWidth = () => {
        return 'calc(' + Math.max(2, String(props.value === undefined ? 0.0 : props.value).length) + 'ch + 1em)';
    }

    return (
        <div className="number-input-wrapper">
            <input 
                className="activity-number" 
                min={0.0} 
                step={0.01} 
                type="number" 
                name={props.name}
                onChange={(event) => {
                    props.change(event);
                    //tempChange(event);
                }}
                value={props.value === undefined ? 0.0 : props.value}
                onKeyDown={handleKeypress}
                style={{width:getWidth()}}
            />
        </div>
        
    )
}
