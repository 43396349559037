import React, { useState, useEffect } from "react";
import { Button, Table } from "reactstrap";

export function SummaryTable(props) {
    const [drillingActivities, setDrillingActivities] = useState([]);
    const [wellsActivities, setWellsActivities] = useState([]);
    const [wellsActivityArrays, setWellsActivityArrays] = useState([]);
    const [wellPadsActivities, setWellPadsActivities] = useState([]);

    useEffect(() => {
        if (props.account === undefined) {
            setDrillingActivities([]);
            setWellsActivityArrays([]);
            setWellsActivities([]);
            setWellPadsActivities([]);
        }
    }, [props.account]);

    const downloadFile = () => {
        const file = new Blob([createFileBody()], { type: 'text/csv' });
        const link = document.createElement("a");
        const url = URL.createObjectURL(file);
        const dateString = new Date().toISOString().substring(0, 10);
        link.href = url;
        link.download = 'CD-C Operator Annual Air Emissions Report - ' + props.operatorName + '_' + new Date().getFullYear();
        document.body.appendChild(link);
        link.click();
        setTimeout(function () {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        }, 0);
    }

    const createFileBody = () => {
        let fileText = '';

        // Drilling Data
        fileText += 'Drilling,Number,Total Hours,Notes\n';
        for (let activity of drillingActivities) {
            fileText += `${props.activityTree.findByActivityItem(activity.activityItem).activity + ' Rigs Used'},${activity.count},${activity.hours},"${activity.notes===null ? '' : activity.notes}"\n`;
        }

        // Wells Data
        fileText += '\nWells,Product,Type,Number,Notes\n';
        for (let activity of wellsActivities) {
            const node = props.activityTree.findByActivityItem(activity.activityItem);
            const product = node.parentNode;
            const superActivity = product.parentNode;
            const foundParent = props.activities.find(activity => activity.activityItem === node.parent);
            const notes = foundParent === undefined ? '' : (foundParent.notes === null ? '' : foundParent.notes);
            fileText += `"${superActivity.activity}","${product.activity}","${node.activity}",${activity.count},"${notes}"\n`;
        }

        // Well Pads Data
        fileText += '\nWell Pads,Type,Number,Total Acres,Notes\n';
        for (let activity of wellPadsActivities) {
            fileText += `"${props.activityTree.findByActivityItem(activity.activityItem).parentNode.activity + ''}",`
                + `"${props.activityTree.findByActivityItem(activity.activityItem).activity}",`
                + `${activity.count},${activity.acres},"${activity.notes===null ? '' : activity.notes}"\n`;
        }

        // Equipment Data
        fileText += '\nWell Pad Equipment,Type,Number,Notes\n';
        for (let equipment of props.equipment) {
            fileText += `"${equipment.type}","${equipment.subtype===null ? '' : equipment.subtype}",${equipment.count},"${equipment.notes===null ? '' : equipment.notes}"\n`;
        }

        // Facilities Data
        fileText += '\nOther Facilities,Number,Notes\n';
        for (let facility of props.facilities) {
            fileText += `${facility.facility},${facility.count},"${facility.notes===null ? '' : facility.notes}"\n`;
        }

        // ERA Data
        fileText += '\nEmission Reduction Activities,Notes\n';
        for (let era of props.era) {
            fileText += `${era.action},"${era.notes===null ? '' : era.notes}"\n`;
        }

        return fileText;
    }

    return (
        <div id='summary-view'>
            <div id="summary-table-title" className="table-title">
                <span>Summary View</span>
                <Button className="export" onClick={downloadFile}>Export to .csv</Button>
            </div>
            <div id="summary-table">
                <DrillingSubTable
                    activities={props.activities}
                    activityTree={props.activityTree}
                    drillingActivities={drillingActivities}
                    setDrillingActivities={setDrillingActivities}
                />
                <WellsSubTable
                    activities={props.activities}
                    activityTree={props.activityTree}
                    wellsActivities={wellsActivities}
                    setWellsActivities={setWellsActivities}
                    wellsActivityArrays={wellsActivityArrays}
                    setWellsActivityArrays={setWellsActivityArrays}
                />
                <WellPadsSubTable
                    activities={props.activities}
                    activityTree={props.activityTree}
                    wellPadsActivities={wellPadsActivities}
                    setWellPadsActivities={setWellPadsActivities}
                />
                <EquipmentSubTable
                    equipment={props.equipment}
                />
                <FacilitiesSubTable
                    facilities={props.facilities}
                />
                <EraSubTable
                    era={props.era}
                />
            </div>

        </div>
    )
}

function DrillingSubTable(props) {
    const fields = [
        'Drilling',
        'Number',
        'Total Hours',
        'Notes'
    ];

    useEffect(() => {
        if (props.activityTree === undefined || props.activities === undefined) return;
        const root = props.activityTree.find('Drilling');
        const leaves = root.getLeaves();
        let drillingActivities = [];
        for (let activity of props.activities) {
            if (leaves.includes(activity.activityItem)) drillingActivities.push(activity);
        }
        drillingActivities.sort((a, b) => a.activityItem - b.activityItem);
        props.setDrillingActivities(drillingActivities);
    }, [props.activities]);

    const mapFieldsToHead = () => {
        return fields.map((field, index) => <th key={'drilling-subtable-th-' + index}>{field}</th>)
    }

    const getRowName = (activity) => {
        return props.activityTree.findByActivityItem(activity.activityItem).activity + ' Rigs Used';
    }

    const getRowCount = (activity) => {
        return activity.count;
    }

    const getRowHours = (activity) => {
        return activity.hours;
    }

    const getRowNotes = (activity) => {
        return (activity.notes === null || activity.notes === '') ? (<span style={{ color: '#BBB' }}>N/A</span>) : activity.notes;
    }


    const mapRowsToTableRow = () => {
        if (props.drillingActivities.length === 0) {
            return (<NoDataRow numFields={fields.length} />);
        } else {
            return props.drillingActivities.map((activity, index) =>
                <tr>
                    <td>{getRowName(activity)}</td>
                    <td>{getRowCount(activity)}</td>
                    <td>{getRowHours(activity)}</td>
                    <td>{getRowNotes(activity)}</td>
                </tr>
            )
        }
    }

    return (
        <Table id='drilling-subtable'>
            <thead>
                <tr>
                    {mapFieldsToHead()}
                </tr>
            </thead>
            <tbody>
                {mapRowsToTableRow()}
            </tbody>
        </Table>
    )
}

function WellsSubTable(props) {
    const fields = [
        'Wells',
        'Product',
        'Type',
        'Number',
        'Notes'
    ];

    useEffect(() => {
        if (props.activityTree === undefined || props.activities === undefined) return;
        const root = props.activityTree.find('Wells');
        const leaves = root.getLeaves();
        let wellsActivities = [];
        for (let activity of props.activities) {
            if (leaves.includes(activity.activityItem)) wellsActivities.push(activity);
        }
        wellsActivities.sort((a, b) => a.activityItem - b.activityItem);
        props.setWellsActivities(wellsActivities);
    }, [props.activities, props.activityTree]);

    useEffect(() => {
        props.setWellsActivityArrays(mapActivitiesToArray(props.wellsActivities));
    }, [props.wellsActivities])

    const mapFieldsToHead = () => {
        return fields.map((field, index) => <th key={'wells-subtable-th-' + index}>{field}</th>)
    }

    const getRowType = (node) => {
        return node.activity;
    }

    const getRowCount = (activity) => {
        return activity.count;
    }

    const getRowNotes = (node) => {
        const foundElement = props.activities.find(activity => activity.activityItem === node.activityItem);
        if (foundElement === undefined) return 'N/A';
        return (foundElement.notes === null || foundElement.notes === '') ? 'N/A' : foundElement.notes;
    }

    const groupBySuperActivity = () => {
        let activityItems = props.wellsActivities.map(activity => activity.activityItem);
        let groupedActivities = {};
        for (let activity of activityItems) {
            const node = props.activityTree.findByActivityItem(activity);
            const superActivity = node.parentNode.parentNode.activityItem;
            if (groupedActivities[superActivity] === undefined) {
                groupedActivities[superActivity] = [activity];
            }
            else {
                groupedActivities[superActivity].push(activity);
            }
        }
        return groupedActivities;
    }

    const groupByProduct = () => {
        let activityItems = props.wellsActivities.map(activity => activity.activityItem);
        let groupedActivities = {};
        for (let activity of activityItems) {
            const node = props.activityTree.findByActivityItem(activity);
            const superActivity = node.parentNode.activityItem;
            if (groupedActivities[superActivity] === undefined) {
                groupedActivities[superActivity] = [activity];
            }
            else {
                groupedActivities[superActivity].push(activity);
            }
        }
        return groupedActivities;
    }

    const mapActivitiesToArray = (activityArray) => {
        const superGroupsUsed = [];
        const productGroupsUsed = [];
        return activityArray.map((activity, index) => {
            let hasSuperTd = false;
            let hasProductTd = false;
            const node = props.activityTree.findByActivityItem(activity.activityItem);
            const product = node.parentNode;
            const superNode = product.parentNode;
            if (!superGroupsUsed.includes(superNode.activityItem)) {
                superGroupsUsed.push(superNode.activityItem);
                hasSuperTd = true;
            }
            if (!productGroupsUsed.includes(product.activityItem)) {
                productGroupsUsed.push(product.activityItem);
                hasProductTd = true;
            }
            return ([
                hasSuperTd ? superNode : null,
                hasProductTd ? product : null,
                getRowType(node),
                getRowCount(activity),
                hasProductTd ? getRowNotes(product) : null
            ])
        })
    }

    const mapActivitiesToTableRow = () => {
        if (props.wellsActivityArrays.length === 0)
            return (<NoDataRow numFields={fields.length} />);

        const superGrouped = groupBySuperActivity();
        const productGrouped = groupByProduct();
        const getGroupSize = (activityItem) => {
            if (superGrouped[activityItem] !== undefined) return superGrouped[activityItem].length;
            if (productGrouped[activityItem] !== undefined) return productGrouped[activityItem].length;
            return 1;
        }
        return props.wellsActivityArrays.map((activity, index) =>
            <tr>
                {activity[0] === null ? null : <td rowSpan={getGroupSize(activity[0].activityItem)} style={{ borderRight: '1px solid var(--bs-table-border-color)' }}>{activity[0].activity}</td>}
                {activity[1] === null ? null : <td rowSpan={getGroupSize(activity[1].activityItem)} style={{ borderRight: '1px solid var(--bs-table-border-color)' }}>{activity[1].activity}</td>}
                <td style={{ borderRight: '1px solid var(--bs-table-border-color)' }}>{activity[2]}</td>
                <td style={{ borderRight: '1px solid var(--bs-table-border-color)' }}>{activity[3]}</td>
                {activity[4] === null ? null : <td rowSpan={getGroupSize(activity[1].activityItem)}>{activity[4] === 'N/A' ? <span style={{ color: '#BBB' }}>N/A</span> : activity[4]}</td>}
            </tr>
        )
    }

    return (
        <Table id='wells-subtable'>
            <thead>
                <tr>
                    {mapFieldsToHead()}
                </tr>
            </thead>
            <tbody>
                {mapActivitiesToTableRow()}
            </tbody>
        </Table>
    )
}

function WellPadsSubTable(props) {
    const fields = [
        'Well Pads',
        'Type',
        'Number',
        'Acres',
        'Notes'
    ];

    useEffect(() => {
        if (props.activityTree === undefined || props.activities === undefined) return;
        const root = props.activityTree.find('Well Pads');
        const leaves = root.getLeaves();
        let wellPadsActivities = [];
        for (let activity of props.activities) {
            if (leaves.includes(activity.activityItem)) wellPadsActivities.push(activity);
        }
        wellPadsActivities.sort((a, b) => a.activityItem - b.activityItem);
        props.setWellPadsActivities(wellPadsActivities);
    }, [props.activities, props.activityTree]);

    const mapFieldsToHead = () => {
        return fields.map((field, index) => <th key={'well-pads-subtable-th-' + index}>{field}</th>)
    }

    const getRowName = (activity) => {
        const node = props.activityTree.findByActivityItem(activity.activityItem);
        return node.parentNode.activity;
    }

    const getRowType = (activity) => {
        const node = props.activityTree.findByActivityItem(activity.activityItem);
        return node.activity;
    }

    const getRowCount = (activity) => {
        return activity.count;
    }

    const getRowAcres = (activity) => {
        return activity.acres;
    }

    const getRowNotes = (activity) => {
        return (activity.notes === null || activity.notes === '') ? (<span style={{ color: '#BBB' }}>N/A</span>) : activity.notes;
    }


    const mapRowsToTableRow = () => {
        if (props.wellPadsActivities.length === 0) {
            return (<NoDataRow numFields={fields.length} />);
        } else {
            return props.wellPadsActivities.map((activity, index) =>
                <tr>
                    <td>{getRowName(activity)}</td>
                    <td>{getRowType(activity)}</td>
                    <td>{getRowCount(activity)}</td>
                    <td>{getRowAcres(activity)}</td>
                    <td>{getRowNotes(activity)}</td>
                </tr>
            )
        }
    }

    return (
        <Table id='well-pads-subtable'>
            <thead>
                <tr>
                    {mapFieldsToHead()}
                </tr>
            </thead>
            <tbody>
                {mapRowsToTableRow()}
            </tbody>
        </Table>
    )
}

function EquipmentSubTable(props) {
    const [equipmentRows, setEquipmentRows] = useState([]);
    const fields = [
        'Well Pad Equipment',
        'Type',
        'Number',
        'Notes'
    ];

    const mapFieldsToHead = () => {
        return fields.map((field, index) => <th key={'equipment-subtable-th-' + index}>{field}</th>)
    }

    const getSubtype = (equipment) => {
        return (equipment.subtype === null || equipment.subtype === '') ? (<span style={{ color: '#BBB' }}>N/A</span>) : equipment.subtype;
    }

    const getRowNotes = (equipment) => {
        return (equipment.notes === null || equipment.notes === '') ? (<span style={{ color: '#BBB' }}>N/A</span>) : equipment.notes;
    }

    useEffect(() => {
        if (props.equipment.length === 0) {
            setEquipmentRows(<NoDataRow numFields={fields.length} />);
        } else {
            const rows = props.equipment.map((equipment, index) =>
                <tr>
                    <td>{equipment.type}</td>
                    <td>{getSubtype(equipment)}</td>
                    <td>{equipment.count}</td>
                    <td>{getRowNotes(equipment)}</td>
                </tr>
            );
            setEquipmentRows(rows);
        }
    }, [props.equipment])

    return (
        <Table id='equipment-subtable'>
            <thead>
                <tr>
                    {mapFieldsToHead()}
                </tr>
            </thead>
            <tbody>
                {equipmentRows}
            </tbody>
        </Table>
    )
}

function FacilitiesSubTable(props) {
    const [facilityRows, setFacilityRows] = useState([]);
    const fields = [
        'Other Facilities',
        'Number',
        'Notes'
    ];

    const mapFieldsToHead = () => {
        return fields.map((field, index) => <th key={'facilities-subtable-th-' + index}>{field}</th>)
    }

    const getRowNotes = (facility) => {
        return (facility.notes === null || facility.notes === '') ? (<span style={{ color: '#BBB' }}>N/A</span>) : facility.notes;
    }

    useEffect(() => {
        if (props.facilities.length === 0) {
            setFacilityRows(<NoDataRow numFields={fields.length} />);
        } else {
            const rows = props.facilities.map((facility, index) =>
                <tr>
                    <td>{facility.facility}</td>
                    <td>{facility.count}</td>
                    <td>{getRowNotes(facility)}</td>
                </tr>
            );
            setFacilityRows(rows);
        }
    }, [props.facilities])

    return (
        <Table id='facilities-subtable'>
            <thead>
                <tr>
                    {mapFieldsToHead()}
                </tr>
            </thead>
            <tbody>
                {facilityRows}
            </tbody>
        </Table>
    )
}

function EraSubTable(props) {
    const [eraRows, setEraRows] = useState([]);
    const fields = [
        'Emission Reduction Activities',
        'Notes'
    ];

    const mapFieldsToHead = () => {
        return fields.map((field, index) => <th key={'era-subtable-th-' + index}>{field}</th>)
    }

    const getRowNotes = (era) => {
        return (era.notes === null || era.notes === '') ? (<span style={{ color: '#BBB' }}>N/A</span>) : era.notes;
    }

    useEffect(() => {
        if (props.era.length === 0) {
            setEraRows(<NoDataRow numFields={fields.length} />);
        } else {
            const rows = props.era.map((era, index) =>
                <tr>
                    <td>{index+1 + ') ' + era.action}</td>
                    <td>{getRowNotes(era)}</td>
                </tr>
            );
            setEraRows(rows);
        }
    }, [props.era])

    return (
        <Table id='era-subtable'>
            <thead>
                <tr>
                    {mapFieldsToHead()}
                </tr>
            </thead>
            <tbody>
                {eraRows}
            </tbody>
        </Table>
    )
}

function NoDataRow(props) {
    return (
        <tr className="no-data-row">
            <td colSpan={props.numFields}>No data to report.</td>
        </tr>
    )
}