import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalHeader, Spinner, Table } from "reactstrap";
import axios from "axios";
import { SummaryTable } from "./SummaryTable";
import { ANNUAL_REPORT_URL, EMAIL_URL, corsConfig } from "../ServerConfig";
import { default as infoIcon } from "../resources/info-circle-svgrepo-com.svg";
import { statusCodes } from "../util/WellStatusCodes";
import { WellList } from "../WellList";

const serviceMap = {
    'Active': 'WOGCCActiveWells',
    'Inactive': 'WOGCCPluggedAndAbandonedWells'
};

export function Dashboard(props) {

    const getContact = () => {
        if (props.report === undefined) {
            return {name:null, email:null, year:null}
        } else {
            return {name:props.report.contactName, email:props.report.contactEmail, year:props.report.year}
        }
    }

    const sendConfirmationEmail = () => {
        const file = new Blob([createFileBody()], { type: 'text/csv' });
        const data = new FormData();
        data.append("file", file);
        data.append("fileName", 'CD-C Operator Annual Air Emissions Report - ' + props.operatorName + '_' + props.report.year + '.csv')
        data.append("email", props.report.contactEmail);
        data.append("name", props.report.contactName);
        data.append("company", props.operatorName);
        data.append("year", props.report.year);
        axios.post(EMAIL_URL + '/confirmation', data);
    }

    const createFileBody = () => {
        const drillingActivities = getActivitiesOfType('Drilling');
        const wellsActivities = getActivitiesOfType('Wells');
        const wellPadsActivities = getActivitiesOfType('Well Pads');
        let fileText = '';

        // Drilling Data
        fileText += 'Drilling,Number,Total Hours,Notes\n';
        for (let activity of drillingActivities) {
            fileText += `${props.activityTree.findByActivityItem(activity.activityItem).activity + ' Rigs Used'},${activity.count},${activity.hours},"${activity.notes===null ? '' : activity.notes}"\n`;
        }

        // Wells Data
        fileText += '\nWells,Product,Type,Number,Notes\n';
        for (let activity of wellsActivities) {
            const node = props.activityTree.findByActivityItem(activity.activityItem);
            const product = node.parentNode;
            const superActivity = product.parentNode;
            const foundParent = props.activities.find(activity => activity.activityItem === node.parent);
            const notes = foundParent === undefined ? '' : (foundParent.notes === null ? '' : foundParent.notes);
            fileText += `"${superActivity.activity}","${product.activity}","${node.activity}",${activity.count},"${notes}"\n`;
        }

        // Well Pads Data
        fileText += '\nWell Pads,Type,Number,Total Acres,Notes\n';
        for (let activity of wellPadsActivities) {
            fileText += `"${props.activityTree.findByActivityItem(activity.activityItem).parentNode.activity + ''}",`
                + `"${props.activityTree.findByActivityItem(activity.activityItem).activity}",`
                + `${activity.count},${activity.acres},"${activity.notes===null ? '' : activity.notes}"\n`;
        }

        // Equipment Data
        fileText += '\nWell Pad Equipment,Type,Number,Notes\n';
        for (let equipment of props.equipment) {
            fileText += `"${equipment.type}","${equipment.subtype===null ? '' : equipment.subtype}",${equipment.count},"${equipment.notes===null ? '' : equipment.notes}"\n`;
        }

        // Facilities Data
        fileText += '\nOther Facilities,Number,Notes\n';
        for (let facility of props.facilities) {
            fileText += `${facility.facility},${facility.count},"${facility.notes===null ? '' : facility.notes}"\n`;
        }

        // ERA Data
        fileText += '\nEmission Reduction Activities,Notes\n';
        for (let era of props.era) {
            fileText += `${era.action},"${era.notes===null ? '' : era.notes}"\n`;
        }

        return fileText;
    }

    const getActivitiesOfType = (type) => {
        if (props.activityTree === undefined || props.activities === undefined) return;
        const root = props.activityTree.find(type);
        const leaves = root.getLeaves();
        let typeActivities = [];
        for (let activity of props.activities) {
            if (leaves.includes(activity.activityItem)) typeActivities.push(activity);
        }
        typeActivities.sort((a, b) => a.activityItem - b.activityItem);
        return typeActivities;
    }

    return (
        <div id="dashboard" className="content-page overflow-auto" hidden={props.hidden}>
            <div id='dashboard-tables'>
                <SummaryTable 
                    account={props.account} 
                    activities={props.activities} 
                    activityTree={props.activityTree}
                    equipment={props.equipment}
                    facilities={props.facilities}
                    era={props.era}
                    operatorName={props.operatorName}
                />
                <div id='well-lists'>
                    <WellList operatorName={props.operatorName} account={props.account} />
                </div>
                <br/>
            </div>
            <div id='summary-sidebar'>
                <SubmitReport report={props.report} reload={props.reloadReport} sendConfirmationEmail={sendConfirmationEmail}/>
                <ContactCard contact={getContact()} />
                <ReportNotesCard notes={props.report.notes} year={props.report.year}/>
            </div>
        </div>
    )
}

function SubmitReport(props) {
    const [waiting, setWaiting] = useState(false);
    const [modal, setModal] = useState(false);

    const toggleModal = () => setModal(!modal);

    const getText = () => {
        if (props.report===undefined || props.report.submitted===undefined) {
            return 'No Report Found'
        } else if (props.report.submitted===true) {
            return 'Report Submitted'
        } else {
            return 'Submit Report'
        }
    }

    const buttonDisabled = () => {
        return props.report===undefined || props.report.submitted!==false;
    }

    const handleSubmit = () => {
        if (props.report.submitted===false) {
            setWaiting(true);
            axios.post(ANNUAL_REPORT_URL + '/submit', {operatorNumber:props.report.operatorNumber, year:props.report.year}, corsConfig).then(() => {
                setWaiting(false);
                props.reload();
                props.sendConfirmationEmail();
            }).catch((error) => {
                setWaiting(false);
                console.error(error);
            });
        }
    }

    const ReportConfirmationModal = () => {
        return (
            <Modal id="report-submit-confirmation-modal" isOpen={modal} toggle={toggleModal} className="shadow-lg">
                <ModalHeader>Are you sure you are ready to submit?</ModalHeader>
                <ModalBody>
                    <div className="confirmation-text">
                        <img src={infoIcon} width={20}/>
                        <p>Once submitted, no further changes can be made to this report.</p>
                    </div>
                    <Button className="submit" onClick={() => {handleSubmit(); toggleModal();}}>Submit</Button>
                </ModalBody>
            </Modal>
        )
    }

    return (
        <div id="report-submit">
            <Button disabled={buttonDisabled()} onClick={toggleModal}>{waiting ? <Spinner size={"sm"}/> : getText()}</Button>    
            <ReportConfirmationModal/>
        </div>
    )
} 

function ContactCard(props) {

    return (
        <div id='contact-card'>
            <h4>Contact for {props.contact.year}</h4>
            <span>{props.contact.name}</span>
            <br/>
            <span>{props.contact.email}</span>
        </div>
    )
}

function ReportNotesCard(props) {

    return (
        <div id='report-notes-card' hidden={props.notes==='' || props.notes===null}>
            <h4>Notes for {props.year}</h4>
            <div id='report-notes'>{props.notes}</div>
        </div>
    )
}