import React, { useEffect, useState } from 'react';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, Input, InputGroup, InputGroupText, Label, Modal, ModalBody, ModalHeader, Spinner, Tooltip } from 'reactstrap';
import { AUTH_URL, CONTACT_ADDRESS, EMAIL_URL, OPERATORS_URL, corsConfig } from './ServerConfig';
import { default as questionMark } from './resources/question-circle-svgrepo-com.svg'
import axios from 'axios';

export function Signup(props) {
    const [formData, setFormData] = useState({});
    const [validFields, setValidFields] = useState({});
    const [opRequestModal, setOpRequestModal] = useState(false);
    const [activeField, setActiveField] = useState('');

    const toggleOperatorNumberRequestModal = () => setOpRequestModal(!opRequestModal);
    
    const clearData = () => {
        setFormData({});
        setValidFields({});
        setActiveField('');
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setFormData(values => ({...values, [name]: value}))
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        for (let field of Object.keys(validFields)) {
            if (!validFields[field]) return;
        }
        props.setWaiting(true);
        const request = {
            "username":formData.username,
            "password":formData.password,
            "firstName":formData.firstName,
            "lastName":formData.lastName,
            "email":formData.email,
            "operatorNumber":formData.operatorNumber
        };
        axios.post(AUTH_URL + '/signup', request, corsConfig).then(() => {
            props.setWaiting(false);
            props.toggle();
        }).catch((error) => {
            props.setWaiting(false);
            console.error(error);
        });
    }

    const submitOnEnter = (event) => {
        if (event.key === 'Enter' && !buttonDisabled()) handleSubmit(event);
    }

    const handleOperatorName = (employer) => {
        setFormData(values => ({...values, "operatorName": employer}))
    }

    const validateEmail = () => {
        const matches = formData.email.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)!==null;
        return matches;
    }

    const validatePassword = () => {
        const upper = /[A-Z]/;
        const lower = /[a-z]/;
        const number = /[0-9]/;
        var password = formData.password;

        return password.length>=14 && upper.test(password) && lower.test(password) && number.test(password);
    }

    const matchPassword = () => {
        return formData.password == formData.confirmPassword;
    }

    useEffect(() => {
        if (formData.username!==undefined) {
            axios.post(AUTH_URL + '/checkusername', {'username':formData.username}).then((response) => {
                setValidFields(values => ({...values, username: response.data}));
            });
        }

        if (formData.firstName!==undefined) {
            setValidFields(values => ({...values, firstName: formData.firstName!==''}));
        }

        if (formData.lastName!==undefined) {
            setValidFields(values => ({...values, lastName: formData.lastName!==''}));
        }

        if (formData.email!==undefined) {
            setValidFields(values => ({...values, email: validateEmail()}));
        }

        if (formData.password!==undefined) {
            setValidFields(values => ({...values, password: validatePassword()}));
        }
        
        if (formData.confirmPassword!==undefined) {
            setValidFields(values => ({...values, confirmPassword: matchPassword()}));
        }

        if (formData.operatorNumber!==undefined) {
            var payload = {"operatorName":formData.operatorName, "operatorNumber":formData.operatorNumber};
            axios.post(OPERATORS_URL + '/checkid', payload).then((response) => {
                setValidFields(values => ({...values, operatorNumber: response.data}));
            });
        }
    }, [formData])
    
    const buttonDisabled = () => {
        const fields = ['username', 'firstName', 'lastName', 'email', 'password', 'confirmPassword', 'operatorName', 'operatorNumber'];

        for (let field of fields) {
            if (!field in validFields) return true;
            if (!validFields[field]) return true;
        }
        return false;
    }

    return (
        <Modal toggle={props.toggle} className='signup shadow-lg' isOpen={props.visible} onClosed={clearData}>
            <ModalHeader toggle={props.toggle}><span className='signup-header'>Create New Account</span></ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit}>
                    <InputGroup>
                        <InputGroupText>Username</InputGroupText>
                        <Input valid={formData.username!==undefined && validFields.username} 
                            invalid={formData.username!==undefined && !validFields.username} 
                            id='signupUsername'
                            name='username' 
                            placeholder='Username' 
                            onChange={handleChange}
                            onKeyDown={submitOnEnter}
                            autoComplete='off'
                        />
                        <UsernameTooltip validUsername={validFields['username']} target={'signupUsername'}/>
                    </InputGroup>
                    <br/>
                    <InputGroup>
                        <InputGroupText>First Name</InputGroupText>
                        <Input valid={formData.firstName!==undefined && validFields.firstName} 
                            invalid={formData.firstName!==undefined && !validFields.firstName} 
                            name='firstName' 
                            placeholder='First Name' 
                            onChange={handleChange}
                            onKeyDown={submitOnEnter}
                            autoComplete='off'
                        />
                    </InputGroup>
                    <br/>
                    <InputGroup>
                        <InputGroupText>Last Name</InputGroupText>
                        <Input valid={formData.lastName!==undefined && validFields.lastName} 
                            invalid={formData.lastName!==undefined && !validFields.lastName} 
                            name='lastName' 
                            placeholder='Last Name' 
                            onChange={handleChange}
                            onKeyDown={submitOnEnter}
                            autoComplete='off'
                        />
                    </InputGroup>
                    <br/>
                    <InputGroup>
                        <InputGroupText>Email</InputGroupText>
                        <Input valid={formData.email!==undefined && validFields.email} 
                            invalid={formData.email!==undefined && !validFields.email}  
                            type='email' 
                            name='email'
                            placeholder='email@domain.com' 
                            onChange={handleChange}
                            onKeyDown={submitOnEnter}
                            autoComplete='off'
                        />
                    </InputGroup>
                    <br/>
                    <InputGroup>
                        <InputGroupText>Password</InputGroupText>
                        <Input valid={formData.password!==undefined && validFields.password} 
                            invalid={formData.password!==undefined && !validFields.password} 
                            id='signup-password'
                            name='password'
                            type='password' 
                            placeholder='Password'  
                            onChange={handleChange}
                            onKeyDown={submitOnEnter}
                            onFocus={()=>setActiveField('password')}
                            onBlur={()=>setActiveField('')}
                            autoComplete='off'
                        />
                        <PasswordTooltip password={formData['password']} isOpen={activeField==='password'} target={'signup-password'}/>
                    </InputGroup>
                    <br/>
                    <InputGroup>
                        <InputGroupText>Confirm Password</InputGroupText>
                        <Input valid={formData.confirmPassword!==undefined && validFields.confirmPassword} 
                            invalid={formData.confirmPassword!==undefined && !validFields.confirmPassword}
                            id='signup-confirm-password'  
                            name='confirmPassword' 
                            type='password' 
                            placeholder='Password' 
                            onChange={handleChange}
                            onKeyDown={submitOnEnter}
                            onFocus={()=>setActiveField('confirmPassword')}
                            onBlur={()=>setActiveField('')}
                            autoComplete='off'
                        />
                        <ConfirmPasswordTooltip password={formData['password']} confirmPassword={formData['confirmPassword']} isOpen={activeField==='confirmPassword'} target={'signup-confirm-password'} />
                    </InputGroup>
                    <br/>
                    <EmployerSearch handleChange={handleChange} handleOperatorName={handleOperatorName} validFields={validFields} setValidFields={setValidFields}/>
                    <br/>
                    <InputGroup>
                        <InputGroupText>Operator Number</InputGroupText>
                        <Input valid={formData.operatorNumber!==undefined && validFields.operatorNumber} 
                            invalid={formData.operatorNumber!==undefined && !validFields.operatorNumber}  
                            name='operatorNumber' 
                            placeholder='ex. 123456'
                            onChange={handleChange}
                            onKeyDown={submitOnEnter}
                            autoComplete='off'
                        />
                        <Button className='question-button' id='signup-question-button' onClick={toggleOperatorNumberRequestModal}><img src={questionMark} style={{width:'2em'}}/></Button>
                        <Tooltip placement='right' isOpen={validFields['operatorNumber']===false} target='signup-question-button'>Don't know your operator number?</Tooltip>
                    </InputGroup>
                    <br/>
                    <Button onClick={handleSubmit} className='submit' disabled={buttonDisabled()}>{props.waiting ? <Spinner size={"sm"}/> : 'Sign Up'}</Button>
                </Form>
            </ModalBody>
            <OperatorNumberRequestModal isOpen={opRequestModal} toggle={toggleOperatorNumberRequestModal} formData={formData} />
        </Modal>
    );
}

function EmployerSearch(props) {
    const [dropdown, setDropdown] = useState(false);
    const [searchString, setSearchString] = useState();
    const [matchingEmployers, setMatchingEmployers] = useState([]);

    const toggleDropdown = () => {setDropdown(!dropdown)}


    useEffect(() => {
        if (searchString!=undefined) {
            getMatchingOperators();
        }
    }, [searchString])

    const getMatchingOperators = () => {
        axios.post(OPERATORS_URL+'/match', {"input":searchString}).then((response) => {
            setMatchingEmployers(response.data!=''?response.data:[]);
        });
    }

    const handleBlur = (event) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
            setDropdown(false);
        }
    }

    useEffect(() => {
        props.setValidFields(values => ({...values, operatorName: matchingEmployers.includes(searchString)}));
    }, [matchingEmployers])

    return (
        <InputGroup>
            <InputGroupText>Operator</InputGroupText>
            <Dropdown 
                isOpen={dropdown} 
                toggle={() => null} 
            >
                <DropdownToggle className='employer-dropdown'>
                    <Input name='operatorName' 
                        placeholder='Search for employer...'
                        valid={searchString!==undefined && props.validFields.operatorName}
                        invalid={searchString!==undefined && !props.validFields.operatorName}
                        value={searchString} 
                        onChange={(event) => {setSearchString(event.target.value); props.handleChange(event)}}
                        onFocus={() => {getMatchingOperators(); setDropdown(true)}}
                        onBlur={handleBlur}
                        autoComplete='off'
                    />
                </DropdownToggle>
                <DropdownMenu>
                    {matchingEmployers.map(employer => 
                        <DropdownItem 
                            onPointerDown={() => {
                                setSearchString(employer); 
                                props.handleOperatorName(employer);
                                setDropdown(false);
                            }}
                        >
                            {employer}
                        </DropdownItem>
                    )}
                </DropdownMenu>
            </Dropdown>
        </InputGroup>
    )
}

function OperatorNumberRequestModal(props) {
    const [formData, setFormData] = useState({});
    const [waiting, setWaiting] = useState(false);
    const [errorStatus, setErrorStatus] = useState(false);

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setFormData(values => ({...values, [name]: value}));
    }

    const handleSubmit = () => {
        setWaiting(true);
        setErrorStatus(false);
        const payload = {
            "from": formData["email"],
            "name": formData["name"],
            "body": `Request for Operator Number\n\nName: ${formData['name']}\nEmail: ${formData['email']}\nRequested Operator Name: ${formData['operatorName']}\n\nPlease respond within 48 hours.`
        }
        axios.post(EMAIL_URL + '/requestOperatorNumber', payload).then(() => {
            setWaiting(false);
            setFormData({});
            props.toggle();
        }).catch((error) => {
            console.error(error);
            setErrorStatus(true);
            setWaiting(false);
        });
    }

    const nameValid = () => {
        if (formData['name'] === undefined) {
            return null;
        } else if (formData['name'] === '') {
            return false;
        } else {
            return true;
        }
    }

    const emailValid = () => {
        if (formData['email'] === undefined) {
            return null;
        } else {
            return formData.email.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)!==null;
        }
    }

    const operatorNameValid = () => {
        if (formData['operatorName'] === undefined) {
            return null;
        } else if (formData['operatorName'] === '') {
            return false;
        } else {
            return true;
        }
    }

    const buttonDisabled = () => {
        return !(nameValid() && emailValid() && operatorNameValid());
    }

    const getFullName = () => {
        return [props.formData['firstName'], props.formData['lastName']].join(' ');
    }

    const getExistingData = () => {
        setFormData({'operatorName': props.formData['operatorName'], 'name': getFullName(), 'email': props.formData['email']});
    }

    const clearData = () => {
        setFormData({});
        setErrorStatus(false);
        setWaiting(false);
    }

    return (
        <Modal id="request-operator-number-modal" isOpen={props.isOpen} toggle={props.toggle} className="shadow-lg" onOpened={getExistingData} onClosed={clearData}>
            <ModalHeader>Request Operator Number</ModalHeader>
            <ModalBody>
                <div>
                    <div>
                        <Label for="name">Name</Label>
                        <Input name="name" type="text" invalid={nameValid()===false} onChange={handleChange} value={formData['name']}/>
                    </div>
                    <div>
                        <Label for="email">Email</Label>
                        <Input name="email" type="email" invalid={emailValid()===false} onChange={handleChange} value={formData['email']}/>
                    </div>
                    <div>
                        <Label for="operatorName">Operator Name</Label>
                        <Input name="operatorName" type="text" invalid={operatorNameValid()===false} onChange={handleChange} value={formData['operatorName']}/>
                    </div>
                    <br/>
                    <Button id='opco-request-submit' className='submit' disabled={buttonDisabled()} onClick={handleSubmit}>{waiting ? <Spinner size={"sm"}/> : 'Send Request'}</Button>
                    <Tooltip target="opco-request-submit" isOpen={props.isOpen && errorStatus} placement="right">Message could not be sent. Try again later or send your question directly to <a href="mailto:blm-web@air-resource.com">blm-web@air-resource.com</a>.</Tooltip>
                </div>
            </ModalBody>
        </Modal>
    )
}

function PasswordTooltip(props) {
    const validLength = () => {
        if (props.password === undefined) return false;
        return props.password.length >= 14;
    }

    const hasUpper = () => {
        if (props.password === undefined) return false;
        const upper = /[A-Z]/;
        return upper.test(props.password);
    }

    const hasLower = () => {
        if (props.password === undefined) return false;
        const lower = /[a-z]/;
        return lower.test(props.password);
    }

    const hasNumber = () => {
        if (props.password === undefined) return false;
        const number = /[0-9]/;
        return number.test(props.password);
    }

    return (
        <Tooltip placement='right' isOpen={props.isOpen} target={props.target}>
            <ul>
                <li className={validLength() ? 'tooltip-valid' : 'tooltip-invalid'}>Password must be at least 14 characters.</li>
                <li className={hasUpper() ? 'tooltip-valid' : 'tooltip-invalid'}>Password must include at least one uppercase letter.</li>
                <li className={hasLower() ? 'tooltip-valid' : 'tooltip-invalid'}>Password must include at least one lowercase letter.</li>
                <li className={hasNumber() ? 'tooltip-valid' : 'tooltip-invalid'}>Password must include at least one number.</li>
            </ul>
        </Tooltip>
    )
}

function ConfirmPasswordTooltip(props) {
    const passwordsMatch = () => {
        if (props.password === undefined || props.confirmPassword === undefined) return false;
        return props.password === props.confirmPassword;
    }

    return (
        <Tooltip placement='right' isOpen={props.isOpen} target={props.target}>
            <ul>
                <li className={passwordsMatch() ? 'tooltip-valid' : 'tooltip-invalid'}>Passwords must match.</li>
            </ul>
        </Tooltip>
    )
}

function UsernameTooltip(props) {
    const valid = () => {
        if (props.validUsername === undefined) return true;
        return props.validUsername;
    }

    return (
        <Tooltip placement='right' isOpen={!valid()} target={props.target}>
            <ul>
                <li className={valid() ? 'tooltip-valid' : 'tooltip-invalid'}>Username already taken.</li>
            </ul>
        </Tooltip>
    )
}