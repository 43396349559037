import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/App.css';
import './styles/login.css';
import './styles/modals.css';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Login } from './Login';
import { ACTIVITIES_URL, AUTH_URL, EQUIPMENT_URL, corsConfig } from './ServerConfig';
import { Signup } from './Signup';
import { Content } from './Content';
import { Header } from './Header';
import { Footer } from './Footer'
import { useIdleTimer } from 'react-idle-timer';
import { InactivityModal } from './modals/InactivityModal';
import { buildTree } from './Tree';
import { PublicView } from './PublicView';
import { Button } from 'reactstrap';
import { ForgotPassword } from './ForgotPassword';
import { default as caret } from './resources/down-caret-white.svg';

function App() {
	const [account, setAccount] = useState();
	const [invalidLoginFlag, setInvalidLoginFlag] = useState(false);
    const [signupModal, setModal] = useState(false);
	const [forgotPasswordModal, setForgotPasswordModal] = useState(false);
	const [inactive, setInactive] = useState(false);
    const [activityTree, setActivityTree] = useState();
	const [equipmentTypes, setEquipmentTypes] = useState([]);
	const [waiting, setWaiting] = useState(false);
	const [publicViewOpen, setPublicViewOpen] = useState(true);

	// Idle tracking
	const onIdle = () => {
		if (account !== undefined) {
			handleLogout();
			setInactive(true);
			console.log('Logged out at ' + new Date(Date.now()).toUTCString());
		}
	}

	useIdleTimer({
		onIdle,
		onActive: () => {},
		onAction: () => {},
		timeout: 10 * 60 * 1000,
		throttle: 500
	})
	
    const toggleSignup = () => {setModal(!signupModal)}

	const toggleForgotPassword = () => {setForgotPasswordModal(!forgotPasswordModal)}

	const toggleInactivity = () => {setInactive(!inactive)}

	const handleLogin = async (login) => {
		axios.post(AUTH_URL + '/signin', login, corsConfig).then((response) => {
			setWaiting(false);
			setAccount(response.data);
			getEquipmentTypes();
			setInvalidLoginFlag(false);
		}).catch((exception) => {
			setWaiting(false);
			console.error(exception);
			setInvalidLoginFlag(true);
		});
	}

	const getActivityTree = () => {
		axios.get(ACTIVITIES_URL + '/tree', corsConfig).then((response) => {
			setActivityTree(buildTree(response.data));
		}).catch();
	}

	const getEquipmentTypes = () => {
		axios.get(EQUIPMENT_URL + '/types', corsConfig).then((response) => {
			response.data.sort((a,b) => {
				if (a === 'Other') return 1;
				if (b === 'Other') return -1;
				if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
			})
			setEquipmentTypes(response.data);
		}).catch();
	}
	
    const handleLogout = async () => {
        axios.post(AUTH_URL + '/signout', null, corsConfig).then((response) => {
			setAccount();
        }).catch();
    }

	useEffect(() => {
		getActivityTree();
		getEquipmentTypes();
		
		axios.get(AUTH_URL + '/signin/relog', corsConfig).then((response) => {
			if (response.data !== '') {
				setAccount(response.data);
				setPublicViewOpen(false);
			}
		}).catch();
	// eslint-disable-next-line
	}, [])

	const renderContentForPublicOrPrivate = () => {
		if (publicViewOpen) {
			return (
				<PublicView hidden={account!==undefined || !publicViewOpen} activityTree={activityTree}/>
			)
		} else {
			if (account===undefined) {
				return (
					<div id='login-landing'>
						<Login 
							visible={account===undefined && !publicViewOpen} 
							handleLogin={handleLogin} 
							toggleSignup={toggleSignup} 
							toggleForgotPassword={toggleForgotPassword}
							invalid={invalidLoginFlag} 
							setInvalid={setInvalidLoginFlag}
							waiting={waiting}
							setWaiting={setWaiting}
						/>
						<div id='back-button'>
							<Button onClick={() => setPublicViewOpen(true)}><img src={caret} className='left-caret' alt=''/>{'Back to public view'}</Button>
						</div>
					</div>
				)
			} else {
				return (
					<Content 
						visible={account!==undefined} 
						account={account} 
						activityTree={activityTree} 
						inactive={inactive} 
						equipmentTypes={equipmentTypes}
					/>
				)
			}
		}
	}

	return (
		<div className="App">
			<header>
				<Header account={account} logout={handleLogout} publicViewOpen={publicViewOpen} setPublicViewOpen={setPublicViewOpen} />
			</header>
			<main>
				<div className='body'>
					{renderContentForPublicOrPrivate()}
				</div>
				
				<Signup 
					visible={signupModal} 
					toggle={toggleSignup}
					waiting={waiting}
					setWaiting={setWaiting}
				/>
				<ForgotPassword toggle={toggleForgotPassword} visible={forgotPasswordModal}/>
				<InactivityModal open={inactive} toggle={toggleInactivity}/>
			</main>
			
			<footer>
				<Footer/>
			</footer>
		</div>
	);
}

export default App;
