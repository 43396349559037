import React, { useState } from "react";
import {  
    Form, 
    Input, 
    Label,
    Modal, 
    ModalBody, 
    ModalFooter, 
    ModalHeader
} from "reactstrap";
import { ERA_URL, corsConfig } from "../ServerConfig";
import axios from "axios";

export function NewERAModal(props) {
    const [newERA, setNewERA] = useState({0: {action:'', notes:''}});

    const createNewERA = () => {
        const index = Math.max(Object.keys(newERA)) + 1;
        const era = {action:'', notes:''};
        setNewERA(values => ({...values, [index]: era}));
    }

    const handleChange = (event) => {
        const index = Number(event.target.name.split('-')[0]);
        const field = event.target.name.split('-')[1];
        const value = event.target.value;
        
        let temp = newERA[index];
        temp[field] = value;
        setNewERA(values => ({...values, [index]: temp}));
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        let submittedERA = Object.keys(newERA).map(index => false);

        for (let index in newERA) {
            if (newERA[index].action === '') {
                submittedERA[index] = true;
                continue;
            };

            const payload = {
                operatorNumber:props.operator,
                year:props.year,
                action:newERA[index].action,
                notes:newERA[index].notes
            }
            axios.post(ERA_URL + '/create', payload, corsConfig).then((response) => {
                submittedERA[index] = true;
                if (submittedERA.every(submitted => submitted===true)) {
                    setNewERA({0: {action:'', notes:''}});
                    props.toggle();
                }
                props.reload();
            }).catch((error) => {
            console.error(error);
        });
        }
    }

    const canSubmit = () => {
        const eraWithAction = Object.keys(newERA).some(index => newERA[index].action !== '');
        const existingERA = props.era.map(era => era.action);
        const eraNotAlreadyExists = Object.keys(newERA).every(index => !existingERA.includes(newERA[index].action));
        return eraWithAction && eraNotAlreadyExists;
    }

    const mapNewERAToRow = () => {
        return Object.keys(newERA).map((index) => 
            <ERAModalRow 
                era={newERA[index]} 
                index={index} 
                handleChange={handleChange}
            />
        );
    }

    const clearEmptyRows = () => {
        if (Object.keys(newERA).length <= 1) return;

        let temp = newERA;
        let emptyRows = [];
        let hasPopulatedRow = false;
        for (let index in temp) {
            if (temp[index].action === '') {
                emptyRows.push(index);
            } else {
                hasPopulatedRow = true;
            }
        }

        for (let i=hasPopulatedRow?0:1; i<emptyRows.length; i++) {
            delete temp[emptyRows[i]];
        }

        setNewERA(temp);
    }

    return (
        <Modal 
            className="new-wells-modal shadow-lg" 
            isOpen={props.isOpen} 
            toggle={props.toggle}
            onClosed={clearEmptyRows}
        >
            <ModalHeader toggle={props.toggle}><span>Add New ERA</span></ModalHeader>
            <Form onSubmit={handleSubmit}>
                <ModalBody>
                    {mapNewERAToRow()}
                    <div 
                        id="add-new-era" 
                        className="non-activity-row add-new-non-activity"
                        hidden={Object.keys(newERA).length>=6}
                        onClick={createNewERA}
                    >
                        <div className="plus">+</div>
                        <div className="add-text">Add new...</div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Input type='submit' value='Submit' disabled={!canSubmit()}/>
                </ModalFooter>
            </Form>
        </Modal>
    )
}


function ERAModalRow(props) {

    const getAction = () => {
        if (props.era === undefined) return '';
        return props.era.action;
    }

    const getNotes = () => {
        if (props.era === undefined) return '';
        return props.era.notes;
    }

    return (
        <div className="modal-row non-activity-row">
            <div className="non-activity-row-inputs">
                <div className="string-input">
                    <Label for={props.index+'-era'}>Action</Label>
                    <Input 
                        type='text'
                        name={props.index+'-action'} 
                        value={getAction()}
                        onChange={props.handleChange}
                        placeholder="Action..."
                    />
                </div>
            </div>
            
            <div className="string-input">
                    <Label for={props.index+'-notes'}>Notes</Label>
                    <Input 
                        type='text'
                        name={props.index+'-notes'} 
                        value={getNotes()}
                        onChange={props.handleChange}
                        placeholder="Notes (Optional)"
                    />
                </div>
        </div>
    )
}