import React, { useEffect, useState } from 'react';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, Input, InputGroup, InputGroupText, Label, Modal, ModalBody, ModalHeader, Spinner, Tooltip } from 'reactstrap';
import { AUTH_URL, CONTACT_ADDRESS, EMAIL_URL, OPERATORS_URL, corsConfig } from './ServerConfig';
import { default as questionMark } from './resources/question-circle-svgrepo-com.svg'
import axios from 'axios';

export function ForgotPassword(props) {
    const [email, setEmail] = useState();
    const [code, setCode] = useState();

    const renderBody = () => {
        if (email === undefined) return <RequestCode setEmail={setEmail}/>;
        else if (code === undefined) {
            return <ConfirmCode email={email} setCode={setCode} />
        } else {
            return <ResetPassword email={email} code={code} toggleModal={props.toggle}/>
        }
    }

    const handleClosed = () => {
        setEmail();
        setCode();
    }

    return (
        <Modal toggle={props.toggle} className='forgot-password shadow-lg' isOpen={props.visible} onClosed={handleClosed}>
            <ModalHeader toggle={props.toggle}><span className='forgot-password-header'>Reset Password</span></ModalHeader>
            <ModalBody>
                {renderBody()}
            </ModalBody>
        </Modal>
    );
}

function RequestCode(props) {
    const [emailString, setEmailString] = useState('');
    const [waiting, setWaiting] = useState(false);
    const [errorStatus, setErrorStatus] = useState();

    const handleSubmit = () => {
        const email = emailString;
        setWaiting(true);
        axios.post(AUTH_URL + '/requestReset', {"input":email}, corsConfig).then((response) => {
            setWaiting(false);
            props.setEmail(email);
        }).catch((error) => {
            console.error(error);
            setErrorStatus(error.response.status);
            setWaiting(false);
        });
    }

    const handleChange = (event) => {
        setEmailString(event.target.value);
    }

    const validEmail = () => {
        const email = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        return email.test(emailString);
    }

    const submitOnEnter = (event) => {
        if (event.key === 'Enter' && validEmail()) handleSubmit();
    }

    return (
        <div>
            <p>Enter your email to reset your password.</p>
            <Label for='forgot-password-email' >Email:</Label>
            <Input 
                id='request-reset-email'
                type='email' 
                name='forgot-password-email' 
                value={emailString} 
                onChange={handleChange} 
                onKeyDown={submitOnEnter}
                invalid={errorStatus!==undefined || (emailString!=='' && !validEmail())} 
                onFocus={() => setErrorStatus()}
            />
            <RequestTooltip target='request-reset-email' errorStatus={errorStatus} isOpen={errorStatus!==undefined} />
            <br/>
            <Button className='submit' onClick={handleSubmit} disabled={!validEmail()}>{waiting ? <Spinner size={"sm"}/> : 'Submit'}</Button>
        </div>
    )
}

function ConfirmCode(props) {
    const [verificationCode, setVerificationCode] = useState('');
    const [invalidCode, setInvalidCode] = useState(false);
    const [errorStatus, setErrorStatus] = useState();

    useEffect(() => {
        if (verificationCode.length === 6) {
            const code = verificationCode;
            axios.post(AUTH_URL + '/confirmReset', {'email':props.email, 'code':code}, corsConfig).then(() => {
                props.setCode(code);
            }).catch((error) => {
                console.error(error.response);
                setInvalidCode(true);
                setErrorStatus(error.response.status);
            });
        } else {
            if (invalidCode) {
                setInvalidCode(false);
                setErrorStatus();
            }
        }
    }, [verificationCode]);

    const handleChange = (event) => {
        if (event.target.value.length < 7) setVerificationCode(event.target.value.toUpperCase());
    }

    const getWidth = () => {
        return invalidCode ? '6.5em' : '6em';
    }

    return (
        <div>
            <p>A verification code as been sent to <b>{props.email}</b>.</p>
            <p>Note: It may take a few moments for the message to arrive in your inbox.</p>
            <div style={{textAlign:'center'}}>
                <p><b>Enter your verification code below:</b></p>
                <Input 
                    id='verification-code' 
                    type='text' 
                    name='verification-code' 
                    value={verificationCode} 
                    onChange={handleChange} 
                    invalid={invalidCode}
                    style={{width:getWidth()}}
                />
                <ConfirmTooltip target='verification-code' errorStatus={errorStatus} isOpen={errorStatus!==undefined} />
            </div>
            <br/>
        </div>
    )
}

function ResetPassword(props) {
    const [activeField, setActiveField] = useState('');
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [waiting, setWaiting] = useState(false);

    const changePassword = (event) => {
        setPassword(event.target.value);
    }

    const changeConfirmPassword = (event) => {
        setConfirmPassword(event.target.value);
    }

    const validatePassword = () => {
        if (password === undefined) return false;
        const upper = /[A-Z]/;
        const lower = /[a-z]/;
        const number = /[0-9]/;

        return password.length>=14 && upper.test(password) && lower.test(password) && number.test(password);
    }

    const matchPassword = () => {
        return password == confirmPassword;
    }

    const canSubmit = () => {
        return validatePassword() && matchPassword();
    }

    const submitOnEnter = (event) => {
        if (event.key === 'Enter' && canSubmit()) handleSubmit(event);
    }

    const handleSubmit = () => {
        if (canSubmit()) {
            setWaiting(true);
            axios.post(AUTH_URL + '/reset', {'email':props.email, 'code':props.code, 'password':password}, corsConfig).then(() => {
                setWaiting(false);
                props.toggleModal();
            }).catch((error) => {
                console.error(error);
                setWaiting(false);
            })
        }
    }

    return (
        <div>
            <p><b>Change Password</b></p>
            <InputGroup>
                <InputGroupText>Password</InputGroupText>
                <Input valid={password!==undefined && validatePassword()} 
                    invalid={password!==undefined && !validatePassword()} 
                    id='reset-password'
                    name='password'
                    type='password' 
                    placeholder='Password'  
                    onChange={changePassword}
                    onKeyDown={submitOnEnter}
                    onFocus={()=>setActiveField('password')}
                    onBlur={()=>setActiveField('')}
                    autoComplete='off'
                />
                <PasswordTooltip password={password} isOpen={activeField==='password'} target={'reset-password'}/>
            </InputGroup>
            <br/>
            <InputGroup>
                <InputGroupText>Confirm Password</InputGroupText>
                <Input valid={confirmPassword!==undefined && matchPassword} 
                    invalid={confirmPassword!==undefined && !matchPassword}
                    id='reset-confirm-password'  
                    name='confirmPassword' 
                    type='password' 
                    placeholder='Password' 
                    onChange={changeConfirmPassword}
                    onKeyDown={submitOnEnter}
                    onFocus={()=>setActiveField('confirmPassword')}
                    onBlur={()=>setActiveField('')}
                    autoComplete='off'
                />
                <ConfirmPasswordTooltip password={password} confirmPassword={confirmPassword} isOpen={activeField==='confirmPassword'} target={'reset-confirm-password'} />
            </InputGroup>
            <br/>
            <Button className='submit' onClick={handleSubmit} disabled={!canSubmit()}>{waiting ? <Spinner size={"sm"}/> : 'Change Password'}</Button>
        </div>
    )
}

function RequestTooltip(props) {

    const getTooltipText = () => {
        if (props.errorStatus === 404) {
            return 'No account found with that email. Please check your spelling and try again.'
        }
    }

    return (
        <Tooltip target={props.target} isOpen={props.isOpen} placement='right'>
            {getTooltipText()}
        </Tooltip>
    )
}

function ConfirmTooltip(props) {

    const getTooltipText = () => {
        switch(props.errorStatus) {
            case 400:
                return 'Invalid code. Double-check your email and try again.';
            case 401:
                return 'Code expired. Request a new code to reset password.';
            case 404:
                return 'No password reset request exists for this account. Request a new code to reset password.';
            case 500:
                return 'Internal server error. Please try again later or contact blm-web@air-resource.com for assistance.';
            default:
                return null;
        }
    }

    return (
        <Tooltip target={props.target} isOpen={props.isOpen} placement='right'>
            {getTooltipText()}
        </Tooltip>
    )
}

function PasswordTooltip(props) {
    const validLength = () => {
        if (props.password === undefined) return false;
        return props.password.length >= 14;
    }

    const hasUpper = () => {
        if (props.password === undefined) return false;
        const upper = /[A-Z]/;
        return upper.test(props.password);
    }

    const hasLower = () => {
        if (props.password === undefined) return false;
        const lower = /[a-z]/;
        return lower.test(props.password);
    }

    const hasNumber = () => {
        if (props.password === undefined) return false;
        const number = /[0-9]/;
        return number.test(props.password);
    }

    return (
        <Tooltip placement='right' isOpen={props.isOpen} target={props.target}>
            <ul>
                <li className={validLength() ? 'tooltip-valid' : 'tooltip-invalid'}>Password must be at least 14 characters.</li>
                <li className={hasUpper() ? 'tooltip-valid' : 'tooltip-invalid'}>Password must include at least one uppercase letter.</li>
                <li className={hasLower() ? 'tooltip-valid' : 'tooltip-invalid'}>Password must include at least one lowercase letter.</li>
                <li className={hasNumber() ? 'tooltip-valid' : 'tooltip-invalid'}>Password must include at least one number.</li>
            </ul>
        </Tooltip>
    )
}

function ConfirmPasswordTooltip(props) {
    const passwordsMatch = () => {
        if (props.password === undefined || props.confirmPassword === undefined) return false;
        return props.password === props.confirmPassword;
    }

    return (
        <Tooltip placement='right' isOpen={props.isOpen} target={props.target}>
            <ul>
                <li className={passwordsMatch() ? 'tooltip-valid' : 'tooltip-invalid'}>Passwords must match.</li>
            </ul>
        </Tooltip>
    )
}