import React, { useEffect, useState } from "react";
import {  
    Button,
    Dropdown, 
    DropdownItem, 
    DropdownMenu, 
    DropdownToggle,
    Input,
    InputGroup,
    InputGroupText,
    Label,
    Nav, 
    NavItem, 
    NavLink
} from "reactstrap";
import axios from "axios";
import { OPERATORS_URL, corsConfig } from "./ServerConfig";
import { getReportingYear } from "./util/ReportingYear";
import { default as downCaret } from './resources/down-caret.svg';

export function SideNav(props) {
    const [showActiveOnly, setShowActiveOnly] = useState(true);

    const toggleActiveOnly = () => setShowActiveOnly(!showActiveOnly);

    const handleCheckbox = () => {
        toggleActiveOnly();
    }

    const mapReportYearsToOption = () => {
        return props.reportYears.map((year, index) => 
            <option key={'report-year-option-'+index}>{year}</option>
        );
    }

    const notOperatorAccount = () => {
        return props.account!==undefined && (props.account.roles.includes('ROLE_BLM') || props.account.roles.includes('ROLE_ADMIN'))
    }

    const showTabs = () => {
        if (props.reportSubmitted || props.reportYears === undefined || props.reportYears.length < 1) {
            return (
                <NavItem id={props.activeTab==='dashboard'?'active-tab':null}>
                    <NavLink href="#" onClick={() => props.setActiveTab('dashboard')}>Dashboard</NavLink>
                </NavItem>
            )
        } else {
            return ([
                <NavItem id={props.activeTab==='dashboard'?'active-tab':null}>
                    <NavLink href="#" onClick={() => props.setActiveTab('dashboard')}>Dashboard</NavLink>
                </NavItem>,
                <NavItem id={props.activeTab==='drilling'?'active-tab':null}>
                    <NavLink href="#" onClick={() => props.setActiveTab('drilling')}>Drilling</NavLink>
                </NavItem>,
                <NavItem id={props.activeTab==='wells'?'active-tab':null}>
                    <NavLink href="#" onClick={() => props.setActiveTab('wells')}>Wells</NavLink>
                </NavItem>,
                <NavItem id={props.activeTab==='well-pads'?'active-tab':null}>
                    <NavLink href="#" onClick={() => props.setActiveTab('well-pads')}>Well Pads</NavLink>
                </NavItem>,
                <NavItem id={props.activeTab==='other-facilities'?'active-tab':null}>
                    <NavLink href="#" onClick={() => props.setActiveTab('other-facilities')}>Other Facilities</NavLink>
                </NavItem>,
                <NavItem id={props.activeTab==='era'?'active-tab':null}>
                    <NavLink href="#" onClick={() => props.setActiveTab('era')}>Emission Reduction Activities</NavLink>
                </NavItem>
            ])
        }
    }

    return (
        <Nav vertical className="content-nav">
            <div className="nav-select" hidden={props.account===undefined || !(props.account.roles.includes('ROLE_BLM') || props.account.roles.includes('ROLE_ADMIN'))}>
                <Label for='nav-operator'>
                    <span>Operator</span>
                    <div id='operator-search-show-all'>
                        <Input type="checkbox" name='operator-dropdown-show-all' checked={showActiveOnly} onChange={handleCheckbox}/>
                        <Label for='operator-dropdown-show-all'>With Activity</Label>
                    </div>
                </Label>
                <OperatorSearch 
                    operatorName={props.operatorName} 
                    setOperator={props.setOperator} 
                    setOperatorName={props.setOperatorName} 
                    account={props.account} 
                    allOperators={!showActiveOnly} 
                />
            </div>

            <div className="nav-select" hidden={props.operator==='' || props.operator==='BLM' || props.operator===null || props.reportYears.length===0}>
                <Label for='nav-year'>Year</Label>
                <Input type="select" name='nav-year' onChange={props.changeYear} value={props.year}>
                    {mapReportYearsToOption()}
                </Input>
            </div>

            <Button 
                hidden={props.operator==='' || props.operator==='BLM' || props.operator===null || props.reportYears.includes(getReportingYear())}
                className="create-report-button"
                onClick={notOperatorAccount() ? () => props.setOpenModal('new-report') : props.createNewReport}
            >
                Create Report for {getReportingYear()}
            </Button>
            
            {showTabs()}
        </Nav>
    )
}

function OperatorSearch(props) {
    const [dropdown, setDropdown] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [matchingOperators, setMatchingOperators] = useState([]);

    const verifyAccount = () => {
        return props.account!==undefined && (props.account.roles.includes('ROLE_BLM') || props.account.roles.includes('ROLE_ADMIN'));
    }

    const getMatchingOperators = () => {
        if (props.allOperators) {  
            axios.post(OPERATORS_URL+'/match/cdc/all', {"input":searchString}, corsConfig).then((response) => {
                let data = response.data!==''?response.data:[];
                data.sort((a,b) => a.operatorName.localeCompare(b.operatorName));
                setMatchingOperators(data);
            }).catch();
        } else {
            axios.post(OPERATORS_URL+'/match/active/all', {"input":searchString}, corsConfig).then((response) => {
                let data = response.data!==''?response.data:[];
                data.sort((a,b) => a.operatorName.localeCompare(b.operatorName));
                setMatchingOperators(data);
            }).catch();
        }
    }

    useEffect(() => {
        if (verifyAccount() && searchString!==undefined) {
            getMatchingOperators();
        }
    }, [searchString])

    const handleBlur = (event) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
            setDropdown(false);
            setSearchString(props.operatorName);
        }
    }

    return (
        <div>
            <Dropdown 
                isOpen={dropdown} 
                toggle={() => null} 
                onFocus={() => {setSearchString(''); setDropdown(true)}}
                onBlur={handleBlur}
            >
                <DropdownToggle className='operator-dropdown'>
                    <InputGroup>
                        <Input name='nav-operator' 
                            value={searchString} 
                            onChange={(event) => {setSearchString(event.target.value)}}
                            autoComplete="off"
                        />
                        <InputGroupText><img src={downCaret}></img></InputGroupText>
                    </InputGroup>
                </DropdownToggle>
                <DropdownMenu className="operator-search-dropdown">
                    {matchingOperators.map((operator, index) => 
                        <DropdownItem 
                            key={'operator-dropdown-item-' + index}
                            onPointerDown={() => {
                                props.setOperator(operator.operatorNumber);
                                props.setOperatorName(operator.operatorName); 
                                setSearchString(operator.operatorName);
                                setDropdown(false);
                            }}
                        >
                            {operator.operatorName}
                        </DropdownItem>
                    )}
                </DropdownMenu>
            </Dropdown>
        </div>
    )
}
