import React, { useState, useEffect } from "react";
import { Badge, Card, CardBody, CardTitle, Collapse, Input, ListGroup, ListGroupItem } from "reactstrap";
import { NumberInput } from "../util/NumberInput";
import axios from "axios";
import { EQUIPMENT_URL, corsConfig } from "../ServerConfig";
import { default as trash } from '../resources/trash-svgrepo-com.svg';
import { default as trashBlack } from '../resources/trash-black-svgrepo-com.svg';

export function EquipmentCardGroup(props) {
    const mapToEquipmentCard = () => {
        if (props.equipment === undefined) return null;

        const groupedEquipment = groupEquipmentByType();

        return Object.keys(groupedEquipment).map((equipment, index) => {
            if (groupedEquipment[equipment].length > 1) {
                return <MultiEquipmentCard key={'equipment-card-'+index} equipment={equipment} equipmentList={groupedEquipment[equipment]} operator={props.operator} delete={props.delete}/>
            } else {
                return <EquipmentCard key={'equipment-card-'+index} equipment={groupedEquipment[equipment][0]} operator={props.operator} delete={props.delete}/>
            }
        })
    }
    
    const groupEquipmentByType = () => {
        let groupedEquipment = {};
        for (let item of props.equipment) {
            if (Object.keys(groupedEquipment).includes(item.type)) {
                groupedEquipment[item.type].push(item)
            } else {
                groupedEquipment[item.type] = [item];
            }
        }
        return groupedEquipment;
    }

    return (
        <Card className="activity-card-group">
            <CardTitle>Equipment</CardTitle>
            <CardBody>
                {mapToEquipmentCard()}
            </CardBody>
        </Card>
    )
}

export function EquipmentCard(props) {
    const [notesOpen, setNotesOpen] = useState(false);
    const [notes, setNotes] = useState('');
    const [count, setCount] = useState(0);

    useEffect(() => {
        if (props.equipment===undefined) return;

        setNotes(getNotes);
        setCount(getCount);
    }, [props.equipment])

    const getNotes = () => {
        return props.equipment.notes === null ? '' : props.equipment.notes;
    }

    const getCount = () => {
        return props.equipment.count;
    }

    const changeNotes = (event) => {
        setNotes(event.target.value);
        axios.put(EQUIPMENT_URL + '/update', {id:props.equipment.id, notes:event.target.value, operatorNumber:props.operator}, corsConfig);
    }

    const changeCount = (event) => {
        if (event.target.value === '') {
            setCount(0);
            return;
        }
        const value = parseInt(event.target.value);
        setCount(value);
        axios.put(EQUIPMENT_URL + '/update', {id:props.equipment.id, count:value, operatorNumber:props.operator}, corsConfig);
    }

    const deleteEquipment = () => {
        props.delete(props.equipment.id);
    }

    const deleteButton = (id) => {
        return (
            <div className="delete-button" onClick={deleteEquipment}><img src={trash}/></div>
        )
    }

    const toggleNotes = () => setNotesOpen(!notesOpen);

    return  (
        <Card className="activity-card">
            <CardTitle className="card-title-wrapper">
                <div className="card-title-text">{props.equipment.type}</div>
                {deleteButton()}    
            </CardTitle>
            <CardBody>
                <ListGroup horizontal>
                    <ListGroupItem>
                        <div className="activity-number">
                            <NumberInput value={count} change={changeCount}/>
                        </div>
                        <div className='activity-name'>{props.equipment.subtype === null ? props.equipment.type : props.equipment.subtype}</div>
                    </ListGroupItem>
                </ListGroup>
                <div className="activity-notes" onClick={toggleNotes}>
                        <Badge pill>
                            Notes {notesOpen?"\u2227":"\u2228"}
                        </Badge>
                </div>
                <Collapse isOpen={notesOpen}>
                    <Input type="textarea" value={notes} onChange={changeNotes} rows={4}/>
                </Collapse>
            </CardBody>
        </Card>
    )
}

export function MultiEquipmentCard(props) {
    const [notesOpen, setNotesOpen] = useState(false);
    const [notes, setNotes] = useState({});
    const [counts, setCounts] = useState({});

    useEffect(() => {
        if (props.equipment===undefined) return;

        for (let equipment of props.equipmentList) {
            setNotes(values => ({...values, [subtypeString(equipment)]:getNotes(equipment)}));
            setCounts(values => ({...values, [subtypeString(equipment)]:getCount(equipment)}));
        }
    }, [props.equipmentList])

    const subtypeString = (equipment) => {
        return equipment.type + '.' + equipment.subtype;
    }

    const getNotes = (equipment) => {
        if (equipment.notes === null) return '';
        return equipment.notes;
    }

    const getCount = (equipment) => {
        return equipment.count;
    }

    const changeNotes = (event, equipment) => {
        setNotes(values => ({...values, [subtypeString(equipment)]:event.target.value}));
        axios.put(EQUIPMENT_URL + '/update', {id:equipment.id, notes:event.target.value}, corsConfig);
    }

    const changeCounts = (event, equipment) => {
        if (event.target.value === '') {
            setCounts(values => ({...values, [subtypeString(equipment)]:0}));
            return;
        }
        const value = parseInt(event.target.value);
        setCounts(values => ({...values, [subtypeString(equipment)]:value}));
        axios.put(EQUIPMENT_URL + '/update', {id:equipment.id, count:value}, corsConfig);
    }

    const notesKey = (equipment) => {
        return 'equipment-notes-' + equipment.type.toLowerCase().replace(' ', '-') 
            + (equipment.subtype!==null ? ('-' +  equipment.subtype.toLowerCase().replace(' ', '-')) : null);
    }

    const toggleNotes = () => setNotesOpen(!notesOpen);

    const mapSubtypesToListGroupItem = () => {
        if (props.equipmentList === undefined) return null;
        return props.equipmentList.map((equipment, index) => 
            <ListGroupItem key={'equipment-card-subtype-list-'+index}>
                <div className="activity-number">
                    <NumberInput value={counts[subtypeString(equipment)]} change={(event) => changeCounts(event, equipment)}/>
                </div>
                <div className='activity-name'>{equipment.subtype===null ? equipment.type : equipment.subtype}{deleteButton(equipment.id)}</div>
            </ListGroupItem>
        )
    }

    const mapSubtypesToNotesInput = () => {
        if (props.equipmentList === undefined) return null;
        return props.equipmentList.map((equipment) => 
            <div key={notesKey(equipment)} className="subtype-notes">
                <span>{equipment.subtype===null ? equipment.type : equipment.subtype}</span>
                <Input type="textarea" value={notes[subtypeString(equipment)]} onChange={(event) => changeNotes(event, equipment)} rows={4}/>
            </div>
        )
    }

    const deleteSubtype = (id) => {
        props.delete(id);
    }

    const deleteButton = (id) => {
        return (
            <div className="delete-button" onClick={() => deleteSubtype(id)}><img src={trashBlack}/></div>
        )
    }

    return  (
        <Card className="activity-card">
            <CardTitle>{props.equipment}</CardTitle>
            <CardBody>
                <ListGroup horizontal>
                    {mapSubtypesToListGroupItem()}
                </ListGroup>
                <div className="activity-notes" onClick={toggleNotes}>
                        <Badge pill>
                            Notes {notesOpen?"\u2227":"\u2228"}
                        </Badge>
                </div>
                <Collapse isOpen={notesOpen}>
                    {mapSubtypesToNotesInput()}
                </Collapse>
            </CardBody>
        </Card>
    )
}