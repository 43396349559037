import React, { useEffect, useState } from "react";
import './styles/publicView.css';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, InputGroupText, Label, Spinner, Table } from "reactstrap";
import axios from "axios";
import { SummaryTable } from "./content/SummaryTable";
import { getReportingYear } from "./util/ReportingYear";
import { ACTIVITIES_URL, ANNUAL_REPORT_URL, EQUIPMENT_URL, ERA_URL, FACILITIES_URL, OPERATORS_URL, corsConfig } from "./ServerConfig";
import { statusCodes } from "./util/WellStatusCodes";
import { WellList } from "./WellList";

import { default as downCaret } from './resources/down-caret.svg';

const thisYear = getReportingYear();

export function PublicView(props) {
    const [activities, setActivities] = useState();
    const [year, setYear] = useState(thisYear);
    const [reportYears, setReportYears] = useState([]);
    const [operator, setOperator] = useState('');
    const [operatorName, setOperatorName] = useState('');
    const [equipment, setEquipment] = useState([]);
    const [otherFacilities, setOtherFacilities] = useState([]);
    const [era, setEra] = useState([]);

    const payload = {
        operatorNumber:operator, 
        year:year
    };

    useEffect(() => {
        if (operator !== '') {
            loadReportYears();
        }
    }, [operator]);
    
    useEffect(() => {
        if (operator !== '') {
            setYear(reportYears[reportYears.length-1])
        }
    }, [reportYears]);

    useEffect(() => {
        if (operator !== '') {
            loadActivities();

            getTabData();
        }
    }, [year]);

    const changeYear = (event) => {
        setYear(event.target.value);
    }
    
    const getTabData = () => {
        loadEquipment();
        loadFacilities();
        loadEra();
    }

    const loadReportYears = () => {
        axios.post(ANNUAL_REPORT_URL + '/years/submitted', {input:operator}, corsConfig).then((response) => {
            setReportYears(response.data);
        }).catch((error) => {
            console.error(error);
        });
    }

    const loadActivities = () => {
        axios.post(ACTIVITIES_URL + '/all', payload, corsConfig).then((response) => {
            setActivities(response.data.activities);
        }).catch((error) => {
            console.error(error);
        });
    }

    const loadEquipment = () => {
        axios.post(EQUIPMENT_URL, payload, corsConfig).then((response) => {
            let data = response.data;
            data.sort((a,b) => {
                if (a.type > b.type) {
                    return 1;
                } else if (a.type < b.type) {
                    return -1;
                } else {
                    if (a.type === b.type) {
                        if (a.subtype > b.subtype) {
                            return 1;
                        } else if (a.subtype < b.subtype) {
                            return -1;
                        } else {
                            return 0;
                        }
                    }
                }
            });
            setEquipment(data);
        }).catch((error) => {
            console.error(error);
        });
    }

    const loadFacilities = () => {
        axios.post(FACILITIES_URL, payload, corsConfig).then((response) => {
            setOtherFacilities(response.data);
        }).catch((error) => {
            console.error(error);
        });
    }

    const loadEra = () => {
        axios.post(ERA_URL, payload, corsConfig).then((response) => {
            setEra(response.data);
        }).catch((error) => {
            console.error(error);
        });
    }

    const mapReportYearsToOption = () => {
        if (noReports()) {
            return (
                <option>No reports found</option>
            )
        }
        return reportYears.map((year, index) => 
            <option key={'report-year-option-'+index}>{year}</option>
        );
    }

    const noReports = () => operator!=='' && (reportYears===undefined || reportYears.length<1);

    return (
        <div id="public-view" className="overflow-auto" hidden={props.hidden}>
            <div id='public-selects' className="shadow-sm">
                <div>
                    <Label for='nav-operator'>
                        <span>Operator</span>
                    </Label>
                    <OperatorSearch
                        setOperator={setOperator}
                        setOperatorName={setOperatorName}
                        operatorName={operatorName}
                    />
                </div>
                <div>
                    <Label for='nav-year'>Year</Label>
                    <Input type="select" name='nav-year' onChange={changeYear} value={year} disabled={reportYears===undefined || reportYears.length<1} style={noReports() ? {color:'#888'} : null}>
                        {mapReportYearsToOption()}
                    </Input>
                </div>
            </div>
            <div id="public-tables">
                <SummaryTable  
                    activities={activities} 
                    activityTree={props.activityTree}
                    equipment={equipment}
                    facilities={otherFacilities}
                    era={era}
                    operatorName={operatorName}
                />
                <div id='well-lists'>
                    <WellList operatorName={operatorName} />
                </div>
            </div>
            <br/>
        </div>
    )
}

function OperatorSearch(props) {
    const [dropdown, setDropdown] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [matchingOperators, setMatchingOperators] = useState([]);

    const getMatchingOperators = () => {
        axios.post(OPERATORS_URL+'/match/active/all', {"input":searchString}, corsConfig).then((response) => {
            let data = response.data!==''?response.data:[];
            data.sort((a,b) => a.operatorName.localeCompare(b.operatorName));
            setMatchingOperators(data);
        }).catch();
    }

    useEffect(() => {
        if (searchString!==undefined) {
            getMatchingOperators();
        }
    }, [searchString])

    const handleBlur = (event) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
            setDropdown(false);
            setSearchString(props.operatorName);
        }
    }

    return (
        <div>
            <Dropdown 
                isOpen={dropdown} 
                toggle={() => null} 
                onFocus={() => {setSearchString(''); setDropdown(true)}}
                onBlur={handleBlur}
            >
                <DropdownToggle className='operator-dropdown'>
                    <InputGroup>
                        <Input name='nav-operator' 
                            value={searchString} 
                            onChange={(event) => {setSearchString(event.target.value)}}
                            autoComplete="off"
                        />
                        <InputGroupText><img src={downCaret}></img></InputGroupText>
                    </InputGroup>
                </DropdownToggle>
                <DropdownMenu
                    className="operator-search-dropdown"
                >
                    {matchingOperators.map((operator, index) => 
                        <DropdownItem 
                            key={'operator-dropdown-item-' + index}
                            onPointerDown={() => {
                                props.setOperator(operator.operatorNumber);
                                props.setOperatorName(operator.operatorName);
                                setSearchString(operator.operatorName);
                                setDropdown(false);
                            }}
                        >
                            {operator.operatorName}
                        </DropdownItem>
                    )}
                </DropdownMenu>
            </Dropdown>
        </div>
    )
}