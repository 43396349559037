import React, { useState, useEffect } from "react";
import { Badge, Card, CardBody, CardTitle, Collapse, Input, ListGroup, ListGroupItem } from "reactstrap";
import { NumberInput } from "../util/NumberInput";
import { ACTIVITIES_URL, corsConfig } from "../ServerConfig";
import { default as trash } from '../resources/trash-svgrepo-com.svg';
import axios from "axios";

export function DrillingCard(props) {
    const [notesOpen, setNotesOpen] = useState(false);
    const [notes, setNotes] = useState('');
    const [count, setCount] = useState(0);
    const [hours, setHours] = useState(0);

    useEffect(() => {
        setNotes(getNotes());
        setCount(getCount());
        setHours(getHours());
    }, [props.activities])

    const createPayload = (obj) => {
        const foundElement = findActivity(props.node.activityItem);
        let payload = {
            operatorNumber: foundElement.operatorNumber,
            year: foundElement.year,
            activityItem: foundElement.activityItem
        };

        for (let key in obj) {
            payload[key] = obj[key];
        }

        return payload;
    }

    const findActivity = (activityItem) => {
        return props.activities.find(activity => activity.activityItem === activityItem);
    }

    const getNotes = () => {
        const foundElement = findActivity(props.node.activityItem);
        if (foundElement !== undefined && foundElement.notes !== null) return foundElement.notes;
        return '';
    }
    
    const getCount = () => {
        const foundElement = findActivity(props.node.activityItem);
        if (foundElement !== undefined) return foundElement.count;
        return 0;
    }

    const getHours = () => {
        const foundElement = findActivity(props.node.activityItem);
        if (foundElement !== undefined) return foundElement.hours;
        return 0;
    }

    const getName = () => {
        return props.node === undefined ? null : props.node.activity;
    }

    const changeNotes = (event) => {
        setNotes(event.target.value);
        axios.put(ACTIVITIES_URL + '/update', createPayload({notes:event.target.value}), corsConfig);
    }

    const changeCount = (event) => {
        if (event.target.value === '') {
            setCount(0);
            return;
        }
        const value = parseInt(event.target.value);
        setCount(value);
        axios.put(ACTIVITIES_URL + '/update', createPayload({count:value}), corsConfig);
    }

    const changeHours = (event) => {
        if (event.target.value === '') {
            setHours(0);
            return;
        }
        const value = parseInt(event.target.value);
        setHours(value);
        axios.put(ACTIVITIES_URL + '/update', createPayload({hours:value}), corsConfig);
    }

    const deleteCard = () => {
        props.delete([props.node.activityItem]);
    }

    const deleteButton = () => {
        return (
            <div className="delete-button" onClick={deleteCard}><img src={trash}/></div>
        )
    }

    const toggleNotes = () => setNotesOpen(!notesOpen);

    return  (
        <Card className="activity-card">
            <CardTitle className="card-title-wrapper">
                <div className="card-title-text">{getName()}</div>
                {deleteButton()}    
            </CardTitle>
            <CardBody>
                <ListGroup horizontal><ListGroupItem>
                        <div className="activity-number primary-metric">
                            <NumberInput value={count} change={changeCount}/>
                        </div>
                        <div className='activity-name'>rigs used</div>
                    </ListGroupItem>
                    
                    <ListGroupItem>
                        <div className="activity-number">
                            <NumberInput value={hours} change={changeHours}/>
                        </div>
                        <div className='activity-name'>hours of operation</div>
                    </ListGroupItem>
                </ListGroup>
                <div className="activity-notes" onClick={toggleNotes}>
                        <Badge pill>
                            Notes {notesOpen?"\u2227":"\u2228"}
                        </Badge>
                </div>
                <Collapse isOpen={notesOpen}>
                    <Input type="textarea" value={notes} onChange={changeNotes} rows={4}/>
                </Collapse>
            </CardBody>
        </Card>
    )
}

export function AddDrillingCard(props) {
    return (
        <Card hidden={props.hidden} className="add-card" onClick={props.onClick}>
            <CardBody>
                <div className="add-card-body">
                    <div className="plus">+</div>
                    <div className="add-text">Add new...</div>
                </div>
            </CardBody>
        </Card>
    )
}