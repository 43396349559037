import React, { useEffect, useState } from "react";
import {  
    Collapse, 
    Form, 
    Input, 
    InputGroup, 
    InputGroupText,
    Modal, 
    ModalBody, 
    ModalFooter, 
    ModalHeader
} from "reactstrap";
import { ACTIVITIES_URL, corsConfig } from "../ServerConfig";
import { NumberInput } from "../util/NumberInput";
import axios from "axios";

export function NewWellsModal(props) {
    const [activeNodes, setActiveNodes] = useState({});
    const [formData, setFormData] = useState({});

    useEffect(() => {
        if (props.activities === undefined || props.activityTree === undefined) return;
        const activityItems = props.activities.map(activity => activity.activityItem);
        const wellsNode = props.activityTree.find('Wells');

        const initActiveNodes = (node, tree) => {
            if (node.children.length===0) {
                tree[node.activityItem] = activityItems.includes(node.activityItem) ? 'disabled' : false;
            } else {
                for (let child of node.children) {
                    initActiveNodes(child, tree);
                    if (node.children.every(child => tree[child.activityItem]==='disabled')) {
                        tree[node.activityItem] = 'disabled';
                    } else {
                        tree[node.activityItem] = false;
                    }
                }
            }
        }

        let tree = {};
        for (let child of wellsNode.children) {
            initActiveNodes(child, tree);
        }
        setActiveNodes(tree);
    }, [props.activities, props.activityTree]);

    

    const toggleActive = (activity) => {
        if (activeNodes[activity] !== 'disabled') {
            if (activeNodes[activity]) {
                let temp = formData;
                const node = props.activityTree.findByActivityItem(activity);
                clearSubData(node, temp);
                setFormData(temp);
            }
            setActiveNodes(values => ({...values, [activity]:(!activeNodes[activity])}));
        }
    }

    const clearSubData = (node, tree) => {
        delete tree[node.activityItem];
        if (node.children.length>0) {
            for (let child of node.children) {
                clearSubData(child, tree);
            }
        }
    }

    const handleChange = (event) => {
        const activityItem = Number(event.target.name.split('-')[0]);
        const field = event.target.name.split('-')[1];
        const value = event.target.value;
        if (!(activityItem in formData)) {
            let updateObj = {
                activityItem:activityItem,
                operatorNumber:props.operator,
                year:props.year,
                count:0
            }
            updateObj[field] = value;
            setFormData(values => ({...values, [activityItem]: updateObj}));
        } else {
            let updateObj = formData[activityItem];
            updateObj[field] = value;
            setFormData(values => ({...values, [activityItem]: updateObj}));
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        for (let activity in formData) {
            axios.post(ACTIVITIES_URL + '/create', formData[activity], corsConfig).then((response) => {
                setFormData({});
                props.toggle();
                props.reload();
            }).catch((error) => {
            console.error(error);
        });
        }
    }

    const mapActivityGroupsToRow = () => {
        if (props.activities === undefined || props.activityTree === undefined) return;
        const wellsNode = props.activityTree.find('Wells');
        return wellsNode.children.map((node, index) => {
            return (
                <WellsModalActivityGroupRow 
                    toggleActive={toggleActive} 
                    active={activeNodes[node.activityItem]}
                    activeNodes={activeNodes}
                    activities={props.activities}
                    node={node}
                    handleChange={handleChange}
                    key={'wells-modal-activity-group-row-'+index}
                    formData={formData}
                />
            )
        });
    }

    return (
        <Modal className="new-wells-modal shadow-lg" isOpen={props.isOpen} toggle={props.toggle}>
            <ModalHeader toggle={props.toggle}><span>Add New Wells Activity</span></ModalHeader>
            <Form onSubmit={handleSubmit}>
                <ModalBody>
                    {mapActivityGroupsToRow()}
                </ModalBody>
                <ModalFooter>
                    <Input type='submit' value='Submit' disabled={Object.keys(formData).length===0}/>
                </ModalFooter>
            </Form>
        </Modal>
    )
}

function WellsModalActivityGroupRow(props) {

    const mapActivityTypesToRow = () => {
        if (props.node === undefined) return;
        return props.node.children.map((node, index) => {
            return (
                <WellsModalActivityTypeRow 
                    toggleActive={props.toggleActive} 
                    active={props.activeNodes[node.activityItem]}
                    activeNodes={props.activeNodes}
                    activities={props.activities}
                    node={node}
                    handleChange={props.handleChange}
                    key={'wells-modal-activity-type-row-'+index}
                    formData={props.formData}
                />
            )
        })
    }

    return (
        <div className="modal-row">
            <div className="modal-row-head">
                <span 
                    className={"pill" + (props.active==='disabled' ? ' disabled' : '')}
                    onClick={() => props.toggleActive(props.node.activityItem)}
                    style={{backgroundColor:(props.active !== 'disabled' && props.active ? 'var(--primary)' : null)}}
                >
                    <span className="head-caret">{props.active==='disabled' ? '' : (props.active ?"\u2227":"\u2228")}</span>
                    <span className="head-text">{props.node.activity}</span>
                </span>
            </div>
            <Collapse isOpen={props.active !== 'disabled' ? props.active : false}>
                <div className="collapse-content">
                    {mapActivityTypesToRow()}
                </div>
            </Collapse>
        </div>
    );
}

function WellsModalActivityTypeRow(props) {

    const getCount = (activityItem) => {
        if (props.formData[activityItem] === undefined) return 0;
        return props.formData[activityItem].count;
    }

    const getNotes = () => {
        if (props.formData[props.node.activityItem] === undefined) return '';
        return props.formData[props.node.activityItem].notes;
    }

    const inputIsActive = (activityItem) => {
        return props.formData[activityItem] !== undefined;
    }

    const hasNotesInput = () => {
        if (props.formData[props.node.activityItem] === undefined) return false;
        return props.formData[props.node.activityItem].notes !== undefined;
    }

    const alreadyHasNotes = () => {
        const leaves = props.node.getLeaves();
        return props.activities.some(activity => leaves.includes(activity.activityItem));
    }

    const mapActivitiesToInputs = () => {
        if (props.node === undefined) return;
        return props.node.children.map((node, index) => {
            if (props.activeNodes[node.activityItem] === 'disabled') return null;
            return (
                <InputGroup>
                    <InputGroupText 
                        style={inputIsActive(node.activityItem)?{backgroundColor:'var(--primary)', color:'var(--text)'}:null}
                    >
                        {node.activity}
                    </InputGroupText>
                    <NumberInput name={node.activityItem + '-count'} value={getCount(node.activityItem)} change={props.handleChange}/>
                </InputGroup>
            )
        })
    }

    return (
        <div className="modal-row">
            <div className="modal-row-head">
                <span 
                    className={"pill" + (props.active==='disabled' ? ' disabled' : '')}
                    onClick={() => props.toggleActive(props.node.activityItem)}
                    style={{backgroundColor:(props.active !== 'disabled' && props.active ? 'var(--primary)' : null)}}
                >
                    <span className="head-caret">{props.active==='disabled' ? '' : (props.active ?"\u2227":"\u2228")}</span>
                    <span className="head-text">{props.node.activity}</span>
                </span>
            </div>
            <Collapse isOpen={props.active !== 'disabled' ? props.active : false}>
                <div className="collapse-content">
                    <div className="number-input-groups">
                        {mapActivitiesToInputs()}
                    </div>
                    <InputGroup 
                        className="notes-input-group"
                        hidden={alreadyHasNotes()}
                    >
                        <InputGroupText 
                            style={hasNotesInput()?{backgroundColor:'var(--primary)', color:'var(--text)'}:null}
                        >
                            Notes
                        </InputGroupText>
                        <Input type='textarea' name={props.node.activityItem + '-notes'} value={getNotes()} onChange={props.handleChange}/>
                    </InputGroup>
                </div>
            </Collapse>
        </div>
    )
}