import React from "react";
import { Button, Modal, ModalBody } from "reactstrap";

export function InactivityModal(props) {
    return (
        <Modal isOpen={props.open} toggle={props.toggle} id="inactivity-modal" className="shadow-lg">
            <ModalBody>
                <div>Logged out due to inactivity.</div>
                <br/>
                <Button onClick={props.toggle}>OK</Button>
            </ModalBody>
        </Modal>
    )
}