export const statusCodes = {
    'PO':'Producing Oil Well',
    'PG':'Producing Gas Well',
    'DH':'Dry Hole',
    'NI':'Notice of Intent to Abandon',
    'SR':'Subsequent Report of Abandonment',
    'SP':'Well Spudded',
    'UNK':'Unknown',
    'NR':'No Report',
    'SO':'Suspended Operations',
    'DU':'Drilled UnCompleted',
    'O':'Oil Well',
    'G':'Gas Well',
    'C':'Condensate',
    'I':'Injector Well',
    'S':'Source Well',
    'D':'Disposal',
    'M':'Monitor Well',
    'MW':'Monitor Well ( Not for Form 2 Reporting )',
    'ST':'Strat Test',
    'GS':'Gas Storage',
    'GO':'Gas Orphaned',
    'OO':'Oil Orphaned',
    'DO':'Disposal Orphaned',
    'IO':'Injector Orphaned',
    'MO':'Monitor Well Orphaned',
    'LW':'LandOwner Water Well',
    'FL':'Flowing',
    'GL':'Gas Lift',
    'PR':'Pumping Rods',
    'PS':'Pumping Submersible',
    'PH':'Pumping Hydraulic',
    'PL':'Plunger Lift',
    'TA':'Temporarily Abandoned',
    'PA':'Permanently Abandoned',
    'AI':'Active Injector',
    'DR':'Dormant',
    'SI':'Shut-In',
    'AP':'Active Permit',
    'EP':'Expired Permit',
    'DP':'Drilling or Drilled Permit',
    'NO':'Denied or Cancelled',
    'WP':'Waiting on Approval',
    'WD':'Withdrawn'
}

export const inactiveCodes = [
    'DH', 'PA', 'SR', 'SI', 'DR', 'SO',
]