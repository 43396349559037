import React from "react";
import { AddWellsCard, WellsCardGroup } from "./WellsCard";

export function WellsPage(props) {

    const mapToWellsCardGroup = () => {
        if (props.activityTree === undefined || props.activities === undefined) return null;
        const root = props.activityTree.find('Wells');
        
        return (root.children.map((child, index) => {
            const leaves = child.getLeaves();
            const activityItems = props.activities.map(activity => activity.activityItem);
            for (let leaf of leaves) {
                if (activityItems.includes(leaf)) {
                    return <WellsCardGroup 
                        key={'wells-card-group-'+index} 
                        node={child} 
                        activities={props.activities}
                        operator={props.operator}
                        year={props.year}
                        delete={props.delete}
                    />
                }
            }
            return null;
        }))
    }

    const canAdd = () => {
        if (props.activityTree === undefined || props.activities === undefined) return null;
        const existingActivites = props.activities.map(activity => activity.activityItem);
        const possibleActivities = props.activityTree.find('Wells').getLeaves();
        const hasAll = possibleActivities.every((activity) => existingActivites.includes(activity));
        return !hasAll && props.year !== undefined;
    }

    const hasNoWellsActivity = () => {
        if (props.activityTree === undefined || props.activities === undefined) return null;
        const existingActivites = props.activities.map(activity => activity.activityItem);
        const possibleActivities = props.activityTree.find('Wells').getLeaves();
        return !possibleActivities.some(activity => existingActivites.includes(activity));
    }

    return (
        <div hidden={props.hidden} id="wells-page" className="content-page overflow-auto">
            {mapToWellsCardGroup()}
            <AddWellsCard onClick={() => props.setOpenModal('wells')} hidden={!canAdd()} style={hasNoWellsActivity() ? {marginTop:'11px'} : null} />
        </div>
    )
}