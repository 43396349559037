import React, { useEffect, useState } from "react";
import {  
    Collapse, 
    Form, 
    Input, 
    InputGroup, 
    InputGroupText,
    Label,
    Modal, 
    ModalBody, 
    ModalFooter, 
    ModalHeader
} from "reactstrap";
import { ACTIVITIES_URL, EQUIPMENT_URL, corsConfig } from "../ServerConfig";
import { NumberInput } from "../util/NumberInput";
import axios from "axios";
import { NumberDecimalInput } from "../util/NumberDecimalInput";

export function NewWellPadsModal(props) {
    const [activeNodes, setActiveNodes] = useState({});
    const [formData, setFormData] = useState({});
    const [newEquipment, setNewEquipment] = useState({});
    
    const newEquipmentRow = {type:'Dehydrator', subtype:'', count:0, notes:''}

    useEffect(() => {
        if (props.activities === undefined || props.activityTree === undefined) return;
        const activityItems = props.activities.map(activity => activity.activityItem);
        const wellPadsNode = props.activityTree.find('Well Pads');

        const initActiveNodes = (node, tree) => {
            if (node.children.length===0) {
                tree[node.activityItem] = activityItems.includes(node.activityItem) ? 'disabled' : false;
            } else {
                for (let child of node.children) {
                    initActiveNodes(child, tree);
                    if (node.children.every(child => tree[child.activityItem]==='disabled')) {
                        tree[node.activityItem] = 'disabled';
                    } else {
                        tree[node.activityItem] = false;
                    }
                }
            }
        }

        let tree = {'equipment': false};
        for (let child of wellPadsNode.children) {
            initActiveNodes(child, tree);
        }
        setActiveNodes(tree);
    }, [props.activities, props.activityTree]);

    

    const toggleActive = (activity) => {
        if (activeNodes[activity] !== 'disabled') {
            if (activeNodes[activity] && typeof activity === 'Number') {
                let temp = formData;
                const node = props.activityTree.findByActivityItem(activity);
                clearSubData(node, temp);
                setFormData(temp);
            }
            setActiveNodes(values => ({...values, [activity]:(!activeNodes[activity])}));
        }
    }

    const clearSubData = (node, tree) => {
        delete tree[node.activityItem];
        if (node.children.length>0) {
            for (let child of node.children) {
                clearSubData(child, tree);
            }
        }
    }

    const createNewEquipment = () => {
        if (Object.keys(newEquipment).length === 0) {
            setNewEquipment({'0':newEquipmentRow});
        } else {
            const keys = Object.keys(newEquipment).map(key => Number(key));
            const index = Math.max(...keys) + 1;
            setNewEquipment(values => ({...values, [index]: newEquipmentRow}));
        }
    }

    const handleChange = (event) => {
        const activityItem = Number(event.target.name.split('-')[0]);
        const field = event.target.name.split('-')[1];
        const value = event.target.value;
        if (!(activityItem in formData)) {
            let updateObj = {
                activityItem:activityItem,
                operatorNumber:props.operator,
                year:props.year,
                count:0
            }
            updateObj[field] = value;
            setFormData(values => ({...values, [activityItem]: updateObj}));
        } else {
            let updateObj = formData[activityItem];
            updateObj[field] = value;
            setFormData(values => ({...values, [activityItem]: updateObj}));
        }
    }

    const handleEquipmentChange = (event) => {
        const index = Number(event.target.name.split('-')[0]);
        const field = event.target.name.split('-')[1];
        const value = field === 'count' ? Number(event.target.value) : event.target.value;
        
        let temp = newEquipment[index];
        temp[field] = value;
        setNewEquipment(values => ({...values, [index]: temp}));
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        let success = true;
        let submittedActivities = {};
        Object.keys(formData).map(index => submittedActivities[index] = false);

        for (let activity in formData) {
            axios.post(ACTIVITIES_URL + '/create', formData[activity], corsConfig).then((response) => {
                submittedActivities[activity] = true;
                if (Object.keys(submittedActivities).every(submitted => submittedActivities[submitted]===true)) {
                    setFormData({});
                }
                props.reload();
            }).catch((error) => {
                console.error(error);
                success = false;
            });
        }

        let submittedEquipment = Object.keys(newEquipment).map(index => false);
    
        for (let index in newEquipment) {
            if (newEquipment[index].type === '') {
                submittedEquipment[index] = true;
                continue;
            };

            const payload = {
                operatorNumber:props.operator,
                year:props.year,
                type:newEquipment[index].type,
                subtype:newEquipment[index].subtype === '' ? null : newEquipment[index].subtype,
                count:newEquipment[index].count,
                notes:newEquipment[index].notes
            }
            axios.post(EQUIPMENT_URL + '/create', payload, corsConfig).then((response) => {
                submittedEquipment[index] = true;
                if (submittedEquipment.every(submitted => submitted===true)) {
                    setNewEquipment({'0': newEquipmentRow});
                    props.toggle();
                }
                props.reload();
            }).catch((error) => {
                console.error(error);
                success = false;
            });
        }

        if (success) props.toggle();
    }

    const checkDuplicateEquipment = () => {
        let typeSubtypes = [];
        for (let index in newEquipment) {
            let type = newEquipment[index].type;
            let subtype = newEquipment[index].subtype;
            if (typeSubtypes.includes(type+subtype)) {
                return true;
            } else {
                typeSubtypes.push(type+subtype);
            }
        }
        return false;
    }

    const canSubmit = () => {
        const equipmentWithType = Object.keys(newEquipment).some(index => newEquipment[index].type !== '');
        const existingEquipment = props.equipment.map(equipment => equipment.type + (equipment.subtype === null ? '' : equipment.subtype));
        const subtypeDoesNotMatchType = Object.keys(newEquipment).every(index => newEquipment[index].type !== newEquipment[index].subtype || newEquipment[index].type==='');
        const equipmentNotAlreadyExists = Object.keys(newEquipment).every(index => !existingEquipment.includes(newEquipment[index].type + newEquipment[index].subtype));
        const canSubmitActivities = Object.keys(formData).length > 0;
        const noDuplicateEquipmentTypeSubtype = !checkDuplicateEquipment();
        return (canSubmitActivities || (equipmentWithType && activeNodes['equipment'])) && subtypeDoesNotMatchType && equipmentNotAlreadyExists && noDuplicateEquipmentTypeSubtype;
    }

    const mapActivityGroupsToRow = () => {
        if (props.activities === undefined || props.activityTree === undefined) return;
        const wellPadsNode = props.activityTree.find('Well Pads');
        return wellPadsNode.children.map((node, index) => {
            return (
                <WellsModalActivityGroupRow 
                    toggleActive={toggleActive} 
                    active={activeNodes[node.activityItem]}
                    activeNodes={activeNodes}
                    activities={props.activities}
                    node={node}
                    handleChange={handleChange}
                    key={'wells-modal-activity-group-row-'+index}
                    formData={formData}
                />
            )
        });
    }

    return (
        <Modal 
            className="new-wells-modal shadow-lg" 
            isOpen={props.isOpen} 
            toggle={props.toggle}
            onClosed={() => setNewEquipment({})}
        >
            <ModalHeader toggle={props.toggle}><span>Add New Well Pads Activity and Equipment</span></ModalHeader>
            <Form onSubmit={handleSubmit}>
                <ModalBody>
                    {mapActivityGroupsToRow()}
                    <WellsModalEquipmentGroupRow 
                        toggleActive={toggleActive} 
                        active={activeNodes['equipment']}
                        activeNodes={activeNodes}
                        newEquipment={newEquipment}
                        handleChange={handleEquipmentChange}
                        createNewEquipment={createNewEquipment}
                        equipmentTypes={props.equipmentTypes}
                    />
                </ModalBody>
                <ModalFooter>
                    <Input type='submit' value='Submit' disabled={!canSubmit()}/>
                </ModalFooter>
            </Form>
        </Modal>
    )
}

function WellsModalActivityGroupRow(props) {

    const mapActivityTypesToRow = () => {
        if (props.node === undefined) return;
        return props.node.children.map((node, index) => {
            return (
                <WellPadsModalActivityRow 
                    toggleActive={props.toggleActive} 
                    active={props.activeNodes[node.activityItem]}
                    activeNodes={props.activeNodes}
                    node={node}
                    name={node.activity}
                    activityItem={node.activityItem}
                    handleChange={props.handleChange}
                    key={'wells-modal-activity-row-'+index}
                    data={props.formData[node.activityItem]}
                />
            )
        })
    }

    return (
        <div className="modal-row">
            <div className="modal-row-head">
                <span 
                    className={"pill" + (props.active==='disabled' ? ' disabled' : '')}
                    onClick={() => props.toggleActive(props.node.activityItem)}
                    style={{backgroundColor:(props.active !== 'disabled' && props.active ? 'var(--primary)' : null)}}
                >
                    <span className="head-caret">{props.active==='disabled' ? '' : (props.active ?"\u2227":"\u2228")}</span>
                    <span className="head-text">{props.node.activity}</span>
                </span>
            </div>
            <Collapse isOpen={props.active !== 'disabled' ? props.active : false}>
                <div className="collapse-content">
                    {mapActivityTypesToRow()}
                </div>
            </Collapse>
        </div>
    );
}

function WellPadsModalActivityRow(props) {

    const getCount = () => {
        if (props.data === undefined) return 0;
        return props.data.count;
    }

    const getAcres = () => {
        if (props.data === undefined) return 0;
        return props.data.acres;
    }

    const getNotes = () => {
        if (props.data === undefined) return '';
        return props.data.notes;
    }

    const inputIsActive = (field) => {
        if (props.data === undefined) return false;
        return props.data[field] !== undefined;
    }

    return (
        <div className="modal-row">
            <div className="modal-row-head">
                <span 
                    className={"pill" + (props.active==='disabled' ? ' disabled' : '')}
                    onClick={() => props.toggleActive(props.node.activityItem)}
                    style={{backgroundColor:(props.active !== 'disabled' && props.active ? 'var(--primary)' : null)}}
                >
                    <span className="head-caret">{props.active==='disabled' ? '' : (props.active ?"\u2227":"\u2228")}</span>
                    <span className="head-text">{props.name}</span>
                </span>
            </div>
            <Collapse isOpen={props.active !== 'disabled' ? props.active : false}>
                <div className="collapse-content">
                    <div className="number-input-groups">
                        <InputGroup>
                            <InputGroupText 
                                style={inputIsActive('count')?{backgroundColor:'var(--primary)', color:'var(--text)'}:null}
                            >
                                New Pads
                            </InputGroupText>
                            <NumberInput name={props.activityItem + '-count'} value={getCount()} change={props.handleChange}/>
                        </InputGroup>
                        <InputGroup>
                            <InputGroupText 
                                style={inputIsActive('acres')?{backgroundColor:'var(--primary)', color:'var(--text)'}:null}
                            >
                                Total Acres
                            </InputGroupText>
                            <NumberDecimalInput name={props.activityItem + '-acres'} value={getAcres()} change={props.handleChange}/>
                        </InputGroup>
                    </div>
                    <InputGroup className="notes-input-group">
                        <InputGroupText 
                                style={inputIsActive('notes')?{backgroundColor:'var(--primary)', color:'var(--text)'}:null}
                            >
                                Notes
                            </InputGroupText>
                        <Input type='textarea' name={props.activityItem + '-notes'} value={getNotes()} onChange={props.handleChange}/>
                    </InputGroup>
                </div>
            </Collapse>
        </div>
    )
}

function WellsModalEquipmentGroupRow(props) {

    const mapNewEquipmentToRow = () => {
        if (props.newEquipment === undefined) return null;
        const indices = Object.keys(props.newEquipment);
        return indices.map((index) => 
            <WellPadsModalEquipmentRow equipment={props.newEquipment[index]} index={index} handleChange={props.handleChange} equipmentTypes={props.equipmentTypes}/>
        );
    }

    return (
        <div className="modal-row">
            <div className="modal-row-head">
                <span 
                    className={"pill" + (props.active==='disabled' ? ' disabled' : '')}
                    onClick={() => props.toggleActive('equipment')}
                    style={{backgroundColor:(props.active !== 'disabled' && props.active ? 'var(--primary)' : null)}}
                >
                    <span className="head-caret">{props.active==='disabled' ? '' : (props.active ?"\u2227":"\u2228")}</span>
                    <span className="head-text">Equipment</span>
                </span>
            </div>
            <Collapse isOpen={props.active !== 'disabled' ? props.active : false}>
                <div className="collapse-content">
                    {mapNewEquipmentToRow()}
                    <div 
                        id="add-new-equipment" 
                        className="non-activity-row add-new-non-activity"
                        hidden={Object.keys(props.newEquipment).length>=12}
                        onClick={props.createNewEquipment}
                    >
                        <div className="plus">+</div>
                        <div className="add-text">Add new...</div>
                    </div>
                </div>
            </Collapse>
        </div>
    );
}

function WellPadsModalEquipmentRow(props) {

    const getType = () => {
        if (props.equipment === undefined) return '';
        return props.equipment.type;
    }
    
    const getSubtype = () => {
        if (props.equipment === undefined) return '';
        return props.equipment.subtype;
    }
    
    const getCount = () => {
        if (props.equipment === undefined) return 0;
        return props.equipment.count;
    }

    const getNotes = () => {
        if (props.equipment === undefined) return '';
        return props.equipment.notes;
    }

    const mapEquipmentTypesToOption = () => {
        return props.equipmentTypes.map((type, index) => 
            <option>{type}</option>
        )
    }

    return (
        <div className="modal-row non-activity-row">
            <div className="non-activity-row-inputs">
                <div className="string-input">
                    <Label for={props.index+'-type'}>Type</Label>
                    <select 
                        name={props.index+'-type'} 
                        value={getType()}
                        onChange={props.handleChange}
                    >
                        {mapEquipmentTypesToOption()}
                    </select>
                </div>
                
                <div className="string-input">
                    <Label for={props.index+'-subtype'}>Subtype</Label>
                    <Input 
                        type='text'
                        name={props.index+'-subtype'} 
                        value={getSubtype()}
                        onChange={props.handleChange}
                        placeholder="Subtype (Required if more than one of type)"
                    />
                </div>
                
                <div className="number-input">
                    <Label for={props.index+'-count'}>Count</Label>
                    <NumberInput 
                        name={props.index+'-count'} 
                        value={getCount()} 
                        change={props.handleChange}
                    />
                </div>
            </div>
            
            <div className="string-input">
                    <Label for={props.index+'-notes'}>Notes</Label>
                    <Input 
                        type='text'
                        name={props.index+'-notes'} 
                        value={getNotes()}
                        onChange={props.handleChange}
                        placeholder="Notes (Optional)"
                    />
                </div>
        </div>
    )
}