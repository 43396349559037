import React from "react";
import { AddERACard, ERACard } from "./ERACard";

export function ERAPage(props) {

    const mapToERACard = () => {
        if (props.era === undefined) return null;
        
        return props.era.map((activity, index) => 
            <ERACard key={'era-card-'+index} activity={activity} era={props.era} delete={props.delete}/>
        )
    }

    const canAdd = () => {
        return props.year !== undefined;
    }

    return (
        <div hidden={props.hidden} id="era-page" className="content-page overflow-auto">
            {mapToERACard()}
            <AddERACard onClick={() => props.setOpenModal('era')} hidden={!canAdd()}/>
        </div>
    )
}