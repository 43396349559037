import React, { useState } from "react";
import './styles/footer.css';
import { default as logo } from './resources/ars-logo.svg';
import { Button, Input, Label, Modal, ModalBody, ModalHeader, Spinner, Tooltip } from "reactstrap";
import axios from "axios";
import { CONTACT_ADDRESS, EMAIL_URL, corsConfig } from "./ServerConfig";

export function Footer() {
    const [contactModal, setContactModal] = useState(false);

    const toggleModal = () => setContactModal(!contactModal);

    const handleClick = (e) => {
        e.preventDefault();
        setContactModal(true);
    }

	return (
		<div className='footer'>
            <div id="documentation-wrapper">
                <a href="/User Guide.pdf" download>User Guide</a>
            </div>
            <div id="contact-us">
                <span>Questions? <a href='' onClick={handleClick}>Contact Us</a></span>
            </div>
            <ContactModal isOpen={contactModal} toggle={toggleModal}/>
		</div>
	)
}

function ContactModal(props) {
    const [formData, setFormData] = useState({});
    const [waiting, setWaiting] = useState(false);
    const [errorStatus, setErrorStatus] = useState(false);

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setFormData(values => ({...values, [name]: value}));
    }

    const handleSubmit = () => {
        setWaiting(true);
        setErrorStatus(false);
        const payload = {
            "to": CONTACT_ADDRESS,
            "from": formData["email"],
            "name": formData["name"],
            "subject": "Question about Emissions Reporting Tool",
            "body": formData["message"]
        }
        axios.post(EMAIL_URL + '/contact', payload).then(() => {
            setWaiting(false);
            setFormData({});
            props.toggle();
        }).catch((error) => {
            console.error(error);
            setWaiting(false);
            setErrorStatus(true);
        });
    }

    const nameValid = () => {
        if (formData['name'] === undefined) {
            return null;
        } else if (formData['name'] === '') {
            return false;
        } else {
            return true;
        }
    }

    const emailValid = () => {
        if (formData['email'] === undefined) {
            return null;
        } else {
            return formData.email.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)!==null;
        }
    }

    const messageValid = () => {
        if (formData['message'] === undefined) {
            return null;
        } else if (formData['message'] === '') {
            return false;
        } else {
            return true;
        }
    }

    const buttonDisabled = () => {
        return !(nameValid() && emailValid() && messageValid());
    }

    const clearData = () => {
        setFormData({});
        setErrorStatus(false);
        setWaiting(false);
    }

    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} className="shadow-lg" onClosed={clearData}>
            <ModalHeader>Contact Us</ModalHeader>
            <ModalBody>
                <div>
                    <div>
                        <Label for="name">Name</Label>
                        <Input name="name" type="text" invalid={nameValid()===false} onChange={handleChange}/>
                    </div>
                    <div>
                        <Label for="email">Email</Label>
                        <Input name="email" type="email" invalid={emailValid()===false} onChange={handleChange}/>
                    </div>
                    <div>
                        <Label for="message">Message</Label>
                        <Input name="message" type="textarea" invalid={messageValid()===false} onChange={handleChange}/>
                    </div>
                    <br/>
                    <Button id='contact-submit' className='submit' disabled={buttonDisabled()} onClick={handleSubmit}>{waiting ? <Spinner size={"sm"}/> : 'Send'}</Button>
                    <Tooltip target="contact-submit" isOpen={props.isOpen && errorStatus} placement="right">Message could not be sent. Try again later or send your question directly to <a href="mailto:blm-web@air-resource.com">blm-web@air-resource.com</a>.</Tooltip>
                </div>
            </ModalBody>
        </Modal>
    )
}

function ARSBadge(props) {
    return (
        <div className="ars-badge">
            <a href="https://www.air-resource.com/">
                <img id='ars-logo' src={logo}/>
                <div className="ars-badge-text">
                    Created by Air Resource Specialists, Inc.
                </div>
            </a>
        </div>
    )
}