import React, { useState } from 'react';
import { Input, Form, InputGroup, InputGroupText, Button, Spinner } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

export function Login(props) {
    const [formData, setFormData] = useState({username:'', password:''});

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setFormData(values => ({...values, [name]: value}));
        props.setInvalid(false);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        props.setWaiting(true);
        props.handleLogin(formData);
        setFormData(values => ({...values, password:''}));
    }

    const submitOnEnter = (event) => {
        if (event.key === 'Enter') handleSubmit(event);
    }

    const invalidLoginMessage = () => {
        if (props.invalid) return <div id='invalid-login' hidden={!props.invalid}>Invalid Login</div>
        else return <br/>
    }

    return (
        <div hidden={!props.visible} className='login shadow'>
            <Form onSubmit={handleSubmit}>
                <div className='login-header'>
                    <h3>Login</h3>
                </div>
                <InputGroup>
                    <InputGroupText>Username</InputGroupText>
                    <Input name="username" placeholder='Username' onChange={handleChange} onKeyDown={submitOnEnter} value={formData.username} invalid={props.invalid}/>
                </InputGroup>
                <br/>
                <InputGroup>
                    <InputGroupText>Password</InputGroupText>
                    <Input name="password" type='password' placeholder='Password' onChange={handleChange} onKeyDown={submitOnEnter} value={formData.password} invalid={props.invalid}/>
                </InputGroup>
                {invalidLoginMessage()}
                <Button onClick={handleSubmit} className='submit'>{props.waiting ? <Spinner size={"sm"}/> : 'Login'}</Button>
                <div className='signup-prompt'>
                    <span>Need an account? <span className='inline-link' onClick={props.toggleSignup}>Sign up</span></span>
                </div>
                <div className='forgot-password-prompt'>
                    <span>Forgot Password? <span className='inline-link' onClick={props.toggleForgotPassword}>Click Here</span></span>
                </div>
            </Form>
        </div>
    );
}