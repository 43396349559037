import React, { useState } from "react";
import './styles/header.css';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { default as logo } from './resources/blm_logo.svg';
import { default as account } from './resources/abstract-user-flat-4.svg';
import { AUTH_URL } from "./ServerConfig";
import axios from "axios";

export function Header(props) {
    const renderAccountBadge = () => {
        return props.account===undefined ? null : <AccountBadge account={props.account} logout={props.logout}/>;
    }
	return (
		<div className='header shadow'>
            <a href="https://www.blm.gov/" target="_blank">
                <div className='logo'>				
                    <img src={logo} id='logo'/>
                    <div className='logo-text'>
                        <div id='logo-text-top'>
                            U.S. DEPARTMENT OF THE INTERIOR
                        </div>
                        <div id='logo-text-main'>
                            BUREAU OF LAND MANAGEMENT
                        </div>
                    </div>
                </div>
            </a>
			
            <AppTitle/>

            {renderAccountBadge()}
            <LoginSignup visible={props.publicViewOpen} setPublicViewOpen={props.setPublicViewOpen}/>
		</div>
	);
}

function AccountBadge(props) {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggle = () => setMenuOpen(!menuOpen);

    return (
        <Dropdown isOpen={menuOpen} toggle={toggle} id='account-menu'>
            <DropdownToggle className='account-badge'>
                <div className='account-text'>
                    <div id='account-name'>{props.account!==undefined?props.account.username:null}</div>
                    <div id='account-employer'>{props.account!==undefined?props.account.operatorName:null}</div>
                </div>
                <img src={account} id='account-icon'/>
            </DropdownToggle>
            <DropdownMenu right>
                <div id='account-info'>
                    <p style={{fontWeight:'600', fontSize: '1.2em'}}>{`${props.account.firstName} ${props.account.lastName}`}</p>
                    <p>{props.account.email}</p>
                </div>
                <hr style={{margin: '5px'}}/>
                {/*<DropdownItem>Update Account</DropdownItem>*/}
                <DropdownItem onClick={props.logout}>Log out</DropdownItem>
            </DropdownMenu>
        </Dropdown>
        
    )
}

function LoginSignup(props) {
    return (
        <div id='login-signup-link' hidden={!props.visible}>
            <a onClick={() => props.setPublicViewOpen(false)}>Login/Signup</a>
        </div>
    )
}

function AppTitle() {
    return (
        <div id="title-wrapper">
            <div id="app-title">WYOMING EMISSIONS REPORTING</div>
            <div id="app-subtitle">Continental Divide - Creston</div>
        </div>
    )
}