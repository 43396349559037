import React, { useEffect, useState } from "react";
import { Badge, Card, CardBody, Collapse, Input } from "reactstrap";
import axios from "axios";
import { default as trash } from '../resources/trash-svgrepo-com.svg';
import { ERA_URL, corsConfig } from "../ServerConfig";

export function ERACard(props) {
    const [notesOpen, setNotesOpen] = useState(false);
    const [notes, setNotes] = useState('');
    const [action, setAction] = useState('');

    useEffect(() => {
        setNotes(getNotes());
        setAction(getAction());
    }, [props.era])

    const getNotes = () => {
        return props.activity === undefined || props.activity.notes===null ? '' : props.activity.notes;
    }

    const getAction = () => {
        if (props.activity !== undefined) return props.activity.action;
        return 0;
    }
    
    const changeNotes = (event) => {
        setNotes(event.target.value);
        axios.put(ERA_URL + '/update', {id:props.activity.id, notes:event.target.value}, corsConfig);
    }

    const changeAction = (event) => {
        setAction(event.target.value);
        axios.put(ERA_URL + '/update', {id:props.activity.id, action:event.target.value}, corsConfig);
    }

    const deleteCard = (event) => {
        event.preventDefault();
        props.delete(props.activity.id);
    }

    const deleteButton = () => {
        return (
            <div className="delete-button" onClick={deleteCard}><img src={trash} alt="delete"/></div>
        )
    }

    const toggleNotes = (event) => {
        if (!(event.target instanceof HTMLImageElement)) setNotesOpen(!notesOpen);
    }

    return  (
        <Card className="era-activity-card">
            <CardBody>
                <Input type="textarea" className="maintext" value={action} onChange={changeAction}/>
                <div className="activity-notes" onClick={toggleNotes}>
                        <Badge pill>
                            Notes {notesOpen?"\u2227":"\u2228"}
                        </Badge>
                        {deleteButton()}
                </div>
                <Collapse isOpen={notesOpen}>
                    <Input type="textarea" value={notes} onChange={changeNotes}/>
                </Collapse>
            </CardBody>
        </Card>
    )
}

export function AddERACard(props) {
    return (
        <Card hidden={props.hidden} className="add-card" onClick={props.onClick}>
            <CardBody>
                <div className="add-card-body">
                    <div className="plus">+</div>
                    <div className="add-text">Add new...</div>
                </div>
            </CardBody>
        </Card>
    )
}