import React, { useState } from "react";
import {  
    Form, 
    Input, 
    Label,
    Modal, 
    ModalBody, 
    ModalFooter, 
    ModalHeader
} from "reactstrap";
import { FACILITIES_URL, corsConfig } from "../ServerConfig";
import { NumberInput } from "../util/NumberInput";
import axios from "axios";

export function NewFacilityModal(props) {
    const [newFacilities, setNewFacilities] = useState({0: {facility:'', count:0, notes:''}});

    const createNewFacility = () => {
        const index = Math.max(Object.keys(newFacilities)) + 1;
        const facility = {facility:'', count:0, notes:''};
        setNewFacilities(values => ({...values, [index]: facility}));
    }

    const handleChange = (event) => {
        const index = Number(event.target.name.split('-')[0]);
        const field = event.target.name.split('-')[1];
        const value = field === 'count' ? Number(event.target.value) : event.target.value;
        
        let temp = newFacilities[index];
        temp[field] = value;
        setNewFacilities(values => ({...values, [index]: temp}));
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        let submittedFacilities = Object.keys(newFacilities).map(index => false);

        for (let index in newFacilities) {
            if (newFacilities[index].facility === '') {
                submittedFacilities[index] = true;
                continue;
            };

            const payload = {
                operatorNumber:props.operator,
                year:props.year,
                facility:newFacilities[index].facility,
                count:newFacilities[index].count,
                notes:newFacilities[index].notes
            }
            axios.post(FACILITIES_URL + '/create', payload, corsConfig).then((response) => {
                submittedFacilities[index] = true;
                if (submittedFacilities.every(submitted => submitted===true)) {
                    setNewFacilities({0: {facility:'', count:0, notes:''}});
                    props.toggle();
                }
                props.reload();
            }).catch((error) => {
            console.error(error);
        });
        }
    }

    const canSubmit = () => {
        const facilityWithType = Object.keys(newFacilities).some(index => newFacilities[index].facility !== '');
        const existingFacilities = props.facilities.map(facility => facility.facility);
        const facilityNotAlreadyExists = Object.keys(newFacilities).every(index => !existingFacilities.includes(newFacilities[index].facility));
        return facilityWithType && facilityNotAlreadyExists;
    }

    const mapNewFacilitiesToRow = () => {
        return Object.keys(newFacilities).map((index) => 
            <FacilityModalRow 
                facility={newFacilities[index]} 
                index={index} 
                handleChange={handleChange}
            />
        );
    }

    const clearEmptyRows = () => {
        if (Object.keys(newFacilities).length <= 1) return;

        let temp = newFacilities;
        let emptyRows = [];
        let hasPopulatedRow = false;
        for (let index in temp) {
            if (temp[index].facility === '') {
                emptyRows.push(index);
            } else {
                hasPopulatedRow = true;
            }
        }

        for (let i=hasPopulatedRow?0:1; i<emptyRows.length; i++) {
            delete temp[emptyRows[i]];
        }

        setNewFacilities(temp);
    }
    
    return (
        <Modal 
            className="new-wells-modal shadow-lg" 
            isOpen={props.isOpen} 
            toggle={props.toggle}
            onClosed={clearEmptyRows}
        >
            <ModalHeader toggle={props.toggle}><span>Add New Facility</span></ModalHeader>
            <Form onSubmit={handleSubmit}>
                <ModalBody>
                    {mapNewFacilitiesToRow()}
                    <div 
                        id="add-new-facility" 
                        className="non-activity-row add-new-non-activity"
                        hidden={Object.keys(newFacilities).length>=6}
                        onClick={createNewFacility}
                    >
                        <div className="plus">+</div>
                        <div className="add-text">Add new...</div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Input type='submit' value='Submit' disabled={!canSubmit()}/>
                </ModalFooter>
            </Form>
        </Modal>
    )
}


function FacilityModalRow(props) {

    const getFacility = () => {
        if (props.facility === undefined) return '';
        return props.facility.facility;
    }
    
    const getCount = () => {
        if (props.facility === undefined) return 0;
        return props.facility.count;
    }

    const getNotes = () => {
        if (props.facility === undefined) return '';
        return props.facility.notes;
    }

    return (
        <div className="modal-row non-activity-row">
            <div className="non-activity-row-inputs">
                <div className="string-input">
                    <Label for={props.index+'-facility'}>Facility</Label>
                    <Input 
                        type='text'
                        name={props.index+'-facility'} 
                        value={getFacility()}
                        onChange={props.handleChange}
                        placeholder="Facility type..."
                    />
                </div>
                
                <div className="number-input">
                    <Label for={props.index+'-count'}>Count</Label>
                    <NumberInput 
                        name={props.index+'-count'} 
                        value={getCount()} 
                        change={props.handleChange}
                    />
                </div>
            </div>
            
            <div className="string-input">
                    <Label for={props.index+'-notes'}>Notes</Label>
                    <Input 
                        type='text'
                        name={props.index+'-notes'} 
                        value={getNotes()}
                        onChange={props.handleChange}
                        placeholder="Notes (Optional)"
                    />
                </div>
        </div>
    )
}