import React, { useEffect, useState } from "react";
import axios from "axios";
import { activeCodes, inactiveCodes, statusCodes } from "./util/WellStatusCodes";
import { Button, Input, Label, Spinner, Table } from "reactstrap";
import { fields } from "./util/CDCFields";
import './styles/wellList.css';
import { default as caret } from './resources/down-caret.svg';

export function WellList(props) {
    const [wellList, setWellList] = useState([]);
    const [waiting, setWaiting] = useState(false);
    const [active, setActive] = useState(true);
    const [inactive, setInactive] = useState(false);
    const [currSort, setCurrSort] = useState();

    const toggleActive = () => setActive(!active);
    const toggleInactive = () => setInactive(!inactive);

    useEffect(() => {
        if (props.operatorName !== '' && (active || inactive)) {
            setWaiting(true);
            let results = [];
            let lock = false;

            axios.get(getActiveWygisUrl()).then((response) => {
                for (let result of response.data.features) {
                    results.push({
                        api:result.attributes.API_NUMBER,
                        link:result.attributes.WOGCC_LINK,
                        name:result.attributes.UNIT_LEASE + ' ' + result.attributes.WN,
                        field:result.attributes.FIELD_NAME,
                        status:statusCodes[result.attributes.STATUS.toUpperCase()]
                    })
                }
                if (lock === false) {
                    lock = true;
                } else {
                    if (currSort !== undefined) {
                        const sortBy = currSort.split('-')[2];
                        const dir = currSort.split('-')[3];
                        results = sortWells(results, sortBy, dir);
                    }
                    setWellList(results);
                    setWaiting(false);
                }
            }).catch((error) => {
                console.error(error);
                lock = true;
                setWaiting(false);
                return;
            });
            
            axios.get(getInactiveWygisUrl()).then((response) => {
                for (let result of response.data.features) {
                    results.push({
                        api:result.attributes.API_NUMBER,
                        link:result.attributes.WOGCC_LINK,
                        name:result.attributes.UNIT_LEASE + ' ' + result.attributes.WN,
                        field:result.attributes.FIELD_NAME,
                        status:statusCodes[result.attributes.STATUS.toUpperCase()]
                    })
                }
                if (lock === false) {
                    lock = true;
                } else {
                    if (currSort !== undefined) {
                        const sortBy = currSort.split('-')[2];
                        const dir = currSort.split('-')[3];
                        results = sortWells(results, sortBy, dir);
                    }
                    setWellList(results);
                    setWaiting(false);
                }
            }).catch((error) => {
                console.error(error);
                lock = true;
                setWaiting(false);
                return;
            });
        } else {
            setWellList([]);
            setWaiting(false);
        }
    }, [props.operatorName, active, inactive]);

    const mapWellsToTableRow = () => {
        if (wellList.length === 0) {
            return ( 
                <tr>
                    <td colSpan={4} style={{textAlign:'center'}}>No wells found in the CD-C area.</td>
                </tr>
            )
        }
        return wellList.map((well, index) =>
            <tr key={'well-list-table-row-'+index}>
                <td><a href={well.link} target="_blank">{well.api}</a></td>
                <td>{well.name}</td>
                <td>{well.field}</td>
                <td>{well.status}</td>
            </tr>
        )
    }

    const urlify = (string) => {
        const replaceWithHex = (match, offset, string) => {
            return '%' + match.charCodeAt(0).toString(16);
        }
        
        return string.replaceAll(' ', '+').replace(/[^A-Z\+]/, replaceWithHex);
    }

    const getStatusCodesURLString = () => {
        if (active && inactive) return '';
        
        if (active) {
            return '+AND+UPPER%28status%29+NOT+IN+%28'
                + inactiveCodes.map(code => `%27${code}%27`).join('%2C+')
                + '%29';
        } else if (inactive) {
            return '+AND+UPPER%28status%29+IN+%28'
                + inactiveCodes.map(code => `%27${code}%27`).join('%2C+')
                + '%29';
        }
        return '';
    }

    const getActiveWygisUrl = () => {
        return 'https://services.wygisc.org/hostgis/rest/services/GeoHub/WOGCCActiveWells'
            + '/MapServer/0/query?where=FIELD_NAME+IN+%28' + fields.map(field => `%27${urlify(field)}%27`).join('%2C+')
            + '%29+AND+company%3D%27' + urlify(props.operatorName) + '%27'
            + getStatusCodesURLString()
            + '&text=&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&relationParam=&outFields=company%2C+API_NUMBER%2C+WOGCC_LINK%2C+UNIT_LEASE%2C+WN%2C+FIELD_NAME%2C+STATUS&returnGeometry=false&returnTrueCurves=false&maxAllowableOffset=&geometryPrecision=&outSR=&having=&returnIdsOnly=false&returnCountOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&gdbVersion=&historicMoment=&returnDistinctValues=false&resultOffset=&resultRecordCount=&queryByDistance=&returnExtentOnly=false&datumTransformation=&parameterValues=&rangeValues=&quantizationParameters=&f=pjson';
    }

    const getInactiveWygisUrl = () => {
        return 'https://services.wygisc.org/hostgis/rest/services/GeoHub/WOGCCPluggedAndAbandonedWells'
            + '/MapServer/0/query?where=FIELD_NAME+IN+%28' + fields.map(field => `%27${urlify(field)}%27`).join('%2C+')
            + '%29+AND+company%3D%27' + urlify(props.operatorName) + '%27'
            + getStatusCodesURLString()
            + '&text=&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&relationParam=&outFields=company%2C+API_NUMBER%2C+WOGCC_LINK%2C+UNIT_LEASE%2C+WN%2C+FIELD_NAME%2C+STATUS&returnGeometry=false&returnTrueCurves=false&maxAllowableOffset=&geometryPrecision=&outSR=&having=&returnIdsOnly=false&returnCountOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&gdbVersion=&historicMoment=&returnDistinctValues=false&resultOffset=&resultRecordCount=&queryByDistance=&returnExtentOnly=false&datumTransformation=&parameterValues=&rangeValues=&quantizationParameters=&f=pjson';
    }

    const downloadFile = () => {
        const file = new Blob([convertToCsvString()], { type: 'text/csv' });
        const link = document.createElement("a");
        const url = URL.createObjectURL(file);
        const dateString = new Date().toISOString().substring(0,10);
        link.href = url;
        link.download = props.type + '_Wells_' + props.operatorName.replaceAll(' ', '_') + '_' + dateString + '.csv';
        document.body.appendChild(link);
        link.click();
        setTimeout(function() {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        }, 0);
    }

    const convertToCsvString = () => {
        let csvString = 'API Number,Well Name,Field,WOGCC Link,Status';
        for (let well of wellList) {
            csvString += `\n${well.api},${well.name},${well.field},${well.link},${well.status}`
        }
        return csvString;
    }

    const WaitingRow = () => {
        return (
            <tr>
                <td colSpan={4} className="waiting-row"><Spinner size={"sm"}/></td>
            </tr>
        )
    }

    const sortWellList = (sortBy, dir) => {
        setWellList(sortWells(wellList, sortBy, dir));
    }

    const sortWells = (wells, sortBy, dir) => {
        let sortedWells = [...wells];
        sortedWells.sort((a,b) => {
            if (a[sortBy] > b[sortBy]) {
                return dir==='asc' ? 1 : -1;
            } else if (a[sortBy] < b[sortBy]) {
                return dir==='desc' ? 1 : -1;
            } else {
                if (a.api > b.api) {
                    return 1;
                } else if (a.api < b.api) {
                    return -1;
                } else {
                    return 0;
                }
            }
        });
        return sortedWells;
    }

    return (
        <div className="well-list">
            <div className="table-title">
                <span>{'Wells'}</span>
                <div className="well-list-checkbox">
                    <Input type="checkbox" name='well-list-active-checkbox' checked={active} onChange={toggleActive}/>
                    <Label for='well-list-active-checkbox'>Active Wells</Label>
                </div>
                <div className="well-list-checkbox">
                    <Input type="checkbox" name='well-list-inactive-checkbox' checked={inactive} onChange={toggleInactive}/>
                    <Label for='well-list-inactive-checkbox'>Inactive Wells</Label>
                </div>
                <Button className="export" onClick={downloadFile}>Export to .csv</Button>
            </div>
            <div className="well-list-table">
                <Table>
                    <thead>
                        <tr>
                            <th>API Number <SortControls column='api' currSort={currSort} setCurrSort={setCurrSort} sortWells={sortWellList}/></th>
                            <th>Well Name <SortControls column='name' currSort={currSort} setCurrSort={setCurrSort} sortWells={sortWellList}/></th>
                            <th>Field <SortControls column='field' currSort={currSort} setCurrSort={setCurrSort} sortWells={sortWellList}/></th>
                            <th>Status <SortControls column='status' currSort={currSort} setCurrSort={setCurrSort} sortWells={sortWellList}/></th>
                        </tr>
                    </thead>
                    <tbody>
                        {waiting ? <WaitingRow/> : mapWellsToTableRow()}
                    </tbody>
                </Table>
            </div>
        </div>
    )
}

function SortControls(props) {

    const handleClick = (event, dir) => {
        props.setCurrSort(event.target.id);
        props.sortWells(props.column, dir);
    }

    return (
        <div className="sort-controls">
            <img 
                id={`sort-control-${props.column}-asc`} 
                src={caret} 
                className="sort-caret asc" 
                onClick={(event) => handleClick(event, 'asc')}
                style={props.currSort===`sort-control-${props.column}-asc` ? {opacity: 1} : null}
            />
            <img 
                id={`sort-control-${props.column}-desc`} 
                src={caret} 
                className="sort-caret desc" 
                onClick={(event) => handleClick(event, 'desc')}
                style={props.currSort===`sort-control-${props.column}-desc` ? {opacity: 1} : null}
            />
        </div>
    )
}