import React from "react";
import { AddFacilityCard, OtherFacilitiesCard } from "./OtherFacilitiesCard";

export function OtherFacilitiesPage(props) {

    const mapToOtherFacilitiesCard = () => {
        if (props.otherFacilities === undefined) return null;
        
        return props.otherFacilities.map((facility, index) => 
            <OtherFacilitiesCard key={'other-facility-card-'+index} facility={facility} facilities={props.otherFacilities} operator={props.operator} delete={props.delete}/>
        )
    }

    const canAdd = () => {
        return props.year !== undefined;
    }

    return (
        <div hidden={props.hidden} id="other-facilities-page" className="content-page overflow-auto">
            {mapToOtherFacilitiesCard()}
            <AddFacilityCard onClick={() => props.setOpenModal('other-facilities')} hidden={!canAdd()}/>
        </div>
    )
}